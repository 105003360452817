import i18next from "i18next";
export class CancelToken {
  cancelled = false;

  cancel(): void {
    this.cancelled = true;
  }

  isCancelled(): boolean {
    return this.cancelled;
  }

  reset(): void {
    this.cancelled = false;
  }
}

const pollError = {
  response: {
    status: -1,
    data: {
      errorMessage: i18next.t("poll-timeout-error-message"),
    },
  },
};

type OptionalParams<T> = {
  waitCallbackBeforeWait?: () => void;
  waitCallbackAfterWait?: () => void;
  waitCallBackForResult?: (result: T) => void;
  cancelToken?: CancelToken;
  pollInterval?: number;
  pollTimeout?: number;
};

const defaultParams = {
  waitCallbackBeforeWait: () => void 0,
  waitCallbackAfterWait: () => void 0,
  waitCallBackForResult: () => void 0,
  cancelToken: new CancelToken(),
  pollInterval: 5 * 1000,
  pollTimeout: 900 * 1000,
};

export async function poll<T>(
  functionToPoll: (...args: any[]) => Promise<T>,
  argsToFunctionToPoll: any[],
  resultCheckerFunction: (result: T) => boolean,
  optionalParams?: OptionalParams<T>
): Promise<T> {
  const filledParams: Required<OptionalParams<T>> = { ...defaultParams, ...optionalParams };
  const { waitCallbackBeforeWait, waitCallbackAfterWait, waitCallBackForResult, cancelToken, pollInterval, pollTimeout } = filledParams;

  const endTime = Date.now() + pollTimeout;
  let result: T = await wrapWithCancel(functionToPoll, {}, cancelToken, ...argsToFunctionToPoll);
  waitCallBackForResult(result);
  while (resultCheckerFunction(result)) {
    if (cancelToken.isCancelled()) {
      return result;
    }
    waitCallbackBeforeWait();
    await wait(pollInterval);
    if (cancelToken.isCancelled()) {
      return result;
    }
    waitCallbackAfterWait();
    result = await wrapWithCancel(functionToPoll, result, cancelToken, ...argsToFunctionToPoll);
    waitCallBackForResult(result);
    const now = Date.now();
    if (now > endTime) {
      return Promise.reject(pollError);
    }
  }
  return result;

  function wrapWithCancel(functionToPoll, oldResult, token, ...args) {
    if (!token.isCancelled()) {
      return functionToPoll(...args);
    } else {
      return oldResult;
    }
  }
}

function wait(ms: number): Promise<any> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
