import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./config/i18n";
import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";
import kpv from "katal-platform-versions";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import AuthWrapper from "./Components/AuthWrapper/AuthWrapper";

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <script src={kpv.domains.prod + kpv.themes.flo.stable} />
    </Helmet>
    <AuthWrapper>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthWrapper>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
