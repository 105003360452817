import { PartnershipInputType, Realm } from "./Types";

export enum AlertIDs {
  MISSING_CUSTOMER_ID = "missingCustomerIDAlert",
  MISSING_MARKETPLACE = "missingMarketplaceAlert",
  WRONG_MARKETPLACE = "wrongMarketplaceAlert",
  MISSING_STATE_MACHINE_NAME_REDRIVE = "missingStateMachineAlert",
  MISSING_REALM_REDRIVE = "missingRealmAlert",
  WRONG_REALM_REDRIVE = "wrongRealmAlert",
  MISSING_WRONG_OVERRIDE_REDRIVE = "missingWrongOverrideAlert",
  MISSING_WRONG_RESUME_REDRIVE = "missingWrongResumeAlert",
  WRONG_START_DATE_REDRIVE = "wrongStartDateAlert",
  WRONG_END_DATE_REDRIVE = "wrongEndDateAlert",
  MISSING_WRONG_REDRIVE_FAILED_BOOL = "missingWrongRedriveFailedBool",
  MISSING_WRONG_REDRIVE_ABORTED_BOOL = "missingWrongRedriveAbortedBool",
  MISSING_WRONG_REDRIVE_TIMED_OUT_BOOL = "missingWrongRedriveTimedOutBool",
  MISSING_WRONG_REDRIVE_RUNNING_BOOL = "missingWrongRedriveRunningBool",
}

export enum WorkflowStatus {
  SUCCEEDED = "SUCCEEDED",
  RUNNING = "RUNNING",
  FAILED = "FAILED",
  TIMED_OUT = "TIMED_OUT",
  ABORTED = "ABORTED",
}

export enum WorkflowStatusToBadgeTypeMap {
  SUCCEEDED = "success",
  RUNNING = "info",
  FAILED = "warning",
  TIMED_OUT = "warning",
  ABORTED = "warning",
}

export enum RedriveDatesType {
  startDate = 1,
  endDate = 2,
}

export enum RedriveTimesType {
  hours = 4,
  minutes = 8,
}

export enum RedriveCheckboxesType {
  override,
  resume,
  redriveFailed,
  redriveAborted,
  redriveTimedOut,
  redriveRunning,
}

export enum KeyMasterAction {
  DECRYPT = "DECRYPT",
  ENCRYPT = "ENCRYPT",
}

export enum KeyMasterStringSide {
  DECODED_STRING,
  ENCODED_STRING,
}

export enum PartnershipType {
  standard = "standard",
  custom = "custom",
}

export const DEFAULT_PARTNERSHIP_INPUT: PartnershipInputType = {
  [PartnershipType.standard]: {
    apiVersion: "3PB_1.0",
    businessName: "Better Call Saul",
    contactEmailId: "TO_OVERRIDE",
    campaignId: "LYNX_INTEGRATION_TEST",
    contactName: "Saul Goodman",
    contactPhoneNumber: "6076734445",
    businessAddressLine1: "3214 11th Ave",
    businessAddressLine2: "Test2",
    businessCity: "Albuquerque",
    businessState: "NM",
    businessPostalCode: "87108",
    businessCountry: "US",
    businessPhoneNumber: "6077612211",
  },
  [PartnershipType.custom]: {
    apiVersion: "OVERRIDE_THIS",
    campaignId: "LYNX_INTEGRATION_TEST",
    contactEmailId: "TO_OVERRIDE",
    partnerId: "OVERRIDE_THIS",
  },
};

export const PARTNERSHIP_REALMS: Realm = {
  NA: {
    id: "na",
    name: "NA (IAD)",
  },
  FE: {
    id: "fe",
    name: "FE (PDX)",
  },
  EU: {
    id: "eu",
    name: "EU (DUB)",
  },
};

export enum REGISTRATION_STATUS {
  UNKNOWN = "UNKNOWN",
  REGISTRATION_FAILED = "REGISTRATION_FAILED",
  REGISTRATION_PENDING = "REGISTRATION_PENDING",
  REGISTRATION_REJECTED = "REGISTRATION_REJECTED",
  REGISTERED = "REGISTERED",
  CANCELLED = "CANCELLED",
}
