import React, { ReactElement } from "react";
import { KatBox, KatLink } from "@amzn/katal-react";
import styles from "./NavBar.module.css";
import { useTranslation } from "react-i18next";
import { AuthConfig } from "../../lib/AuthConfig";

export const NavBar: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const getLinkClass = () => {
    if (AuthConfig.isAdmin()) {
      return "kat-col-xs-4";
    } else {
      return "kat-offset-xs-8 kat-col-xs-4";
    }
  };

  return (
    <div className={styles.navBar}>
      <KatBox variant="nordic" className={`kat-row ${styles.navBox}`}>
        <section className={"kat-col-xs-6 kat-col-sm-7 kat-col-md-7 kat-col-lg-9 kat-col-xl-7 kat-row"}>
          <KatLink href="/" variant="link">
            <h2>{t("console-title")}</h2>
          </KatLink>
        </section>
        <section
          className={
            "kat-col-xs-6 kat-col-sm-5 kat-col-md-3 kat-offset-md-2 kat-col-lg-3 kat-offset-lg-0 kat-col-xl-2 kat-offset-xl-3 kat-row"
          }
        >
          <KatLink href="/" label={t("search-console-link")} variant="link" className={getLinkClass()} />
          {AuthConfig.isAdmin() && (
            <KatLink href="/redrive" label={t("redrive-workflows-link")} variant="link" className={"kat-col-xs-4"} />
          )}
          {AuthConfig.isAdmin() && (
            <KatLink href="/partnership" label={t("create-partnership-link")} variant="link" className={"kat-col-xs-4"} />
          )}
          {/*{AuthConfig.isAdmin() && <KatLink href="/keymaster" label={t("keymaster-link")} variant="link" className={"kat-col-xs-3"} />}*/}
        </section>
      </KatBox>
    </div>
  );
};
