import React from "react";
import { ResizableBox } from "../ResizableBox/ResizableBox";
import { useTranslation } from "react-i18next";
import { KatButton, KatIcon } from "@amzn/katal-react";

interface ToolsActionsBoxProps {
  isShowResults: boolean;
  isLoading: boolean;
  isCancellable: boolean;
  isLoadingCancellation: boolean;
  onCancelBusinessRegistrationPressed: () => void;
}

export const ToolsActionsBox: React.FC<ToolsActionsBoxProps> = (props) => {
  const { t } = useTranslation();

  const cancelBusinessRegistrationButton = "cancelBusinessRegistrationButton";

  return (
    <ResizableBox showResults={props.isShowResults}>
      <h3>{t("actions-header")}</h3>
      <KatButton
        id={cancelBusinessRegistrationButton}
        disabled={props.isLoading || !props.isCancellable}
        loading={props.isLoadingCancellation}
        onClick={props.onCancelBusinessRegistrationPressed}
        label={cancelBusinessRegistrationButton}
        variant="danger"
      >
        <KatIcon name="trash" size="small" slot="icon" />
        {t("cancel-business-registration-button-label")}
      </KatButton>
    </ResizableBox>
  );
};
