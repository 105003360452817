import styles from "./TablesBox.module.css";
import { KatBox, KatTab, KatTabs } from "@amzn/katal-react";
import { ResizableBox } from "../ResizableBox/ResizableBox";
import React from "react";
import { TableResult } from "../../common/Types";
import ReactJson from "react-json-view";
import { useTranslation } from "react-i18next";

interface TablesBoxProps {
  isShowResults: boolean;
  isLoading: boolean;
  registrationFormDataResult: TableResult;
  registrationRequestResult: TableResult;
  abRegistrationResult: TableResult;
}

export const TablesBox: React.FC<TablesBoxProps> = (props) => {
  const { t } = useTranslation();

  const registrationFormDataTab = "RegistrationFormData";
  const registrationRequestTab = "RegistrationRequest";
  const abRegistrationTab = "ABRegistration";

  const printTabContent = (tableResult: TableResult, isLoading: boolean, loadingMessage: string) => {
    if (isLoading) {
      return loadingMessage;
    } else {
      return tableResult.error ? tableResult.error : <ReactJson src={tableResult.data} enableClipboard displayDataTypes={false} />;
    }
  };

  return (
    <ResizableBox showResults={props.isShowResults}>
      <h3>{t("table-result-header")}</h3>
      <div className={`kat-row ${styles.tableResult}`}>
        <div className="kat-col-sm-12 kat-col-md-15">
          <KatTabs selected={registrationFormDataTab} id="ResultTabs">
            <KatTab label={registrationFormDataTab} tabId={registrationFormDataTab}>
              <KatBox variant="white-shadow">
                {printTabContent(props.registrationFormDataResult, props.isLoading, t("loading-registration-form-data"))}
              </KatBox>
            </KatTab>
            <KatTab label={registrationRequestTab} tabId={registrationRequestTab}>
              <KatBox variant="white-shadow">
                {printTabContent(props.registrationRequestResult, props.isLoading, t("loading-registration-request"))}
              </KatBox>
            </KatTab>
            <KatTab label={abRegistrationTab} tabId={abRegistrationTab}>
              <KatBox variant="white-shadow">
                {printTabContent(props.abRegistrationResult, props.isLoading, t("loading-ab-registration"))}
              </KatBox>
            </KatTab>
          </KatTabs>
        </div>
      </div>
    </ResizableBox>
  );
};
