import React, { ReactElement, useEffect, useRef, useState } from "react";
import { AuthConfig } from "../../lib/AuthConfig";
import { KatAccordion, KatAccordionItem, KatButton, KatLink } from "@amzn/katal-react";
import ReactJson from "react-json-view";
import styles from "./WorkflowBox.module.css";
import { useTranslation } from "react-i18next";
import { WorkflowExecution, WorkflowResult } from "../../common/Types";
import { ResizableBox } from "../ResizableBox/ResizableBox";
import RedriveModal from "../RedriveModal/RedriveModal";
import { WorkflowStatus } from "../../common/Constants";
import { getBadge, getLabel, getWorkflowInfo, WorkflowInfo } from "../../utils/WorkflowUtils";

type WorkflowBoxProps = {
  loading: boolean;
  showResults: boolean;
  customerID: string;
  workflowResult: WorkflowResult;
  realm: string;
};

const REDRIVABLE_STATUS_LIST: Array<WorkflowStatus> = [
  WorkflowStatus.FAILED,
  WorkflowStatus.ABORTED,
  WorkflowStatus.TIMED_OUT,
  WorkflowStatus.RUNNING,
];

export const WorkflowBox: React.FC<WorkflowBoxProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const [showModal, updateShowModal] = useState(false);
  const [arnToRedrive, updateArnToRedrive] = useState(null);
  const [workflowResult, updateWorkflowResult] = useState(props.workflowResult);
  const parentWorkflowIdToAccordionRef = useRef(new Map());

  useEffect(() => {
    updateWorkflowResult(props.workflowResult);
  }, [props.workflowResult]);

  const addRedrivenExecution = (redrivenWorkflow: WorkflowExecution, parentExecutionArn: string) => {
    const newWorkflowArray: Array<WorkflowExecution> = workflowResult.data.concat([redrivenWorkflow]);
    const statusToReplaceIndex = newWorkflowArray.findIndex(
      (workflowExecution: WorkflowExecution) => workflowExecution.executionArn === parentExecutionArn
    );
    if (newWorkflowArray[statusToReplaceIndex].status === WorkflowStatus.RUNNING) {
      const abortedRunningExecution = newWorkflowArray[statusToReplaceIndex];
      abortedRunningExecution.status = WorkflowStatus.ABORTED;
      abortedRunningExecution.stopDate = redrivenWorkflow.startDate;
      abortedRunningExecution.workflowError = {
        error: t("workflow-running-aborted-error"),
        cause: t("workflow-running-aborted-cause"),
      };
      newWorkflowArray[statusToReplaceIndex] = abortedRunningExecution;
    }
    const newWorkflowResult: WorkflowResult = {
      data: newWorkflowArray,
    };
    updateWorkflowResult(newWorkflowResult);
  };

  const onRedriveButtonPressed = (event: any): void => {
    updateArnToRedrive(event.target.value);
    updateShowModal(true);
  };

  const openParentAccordion = (parentID: string): void => {
    parentWorkflowIdToAccordionRef.current.get(parentID).expanded = true;
    parentWorkflowIdToAccordionRef.current.get(parentID).scrollIntoView();
  };

  const generateBody = (workflowExecution: WorkflowExecution) => {
    const workflowInfo: WorkflowInfo = getWorkflowInfo(workflowExecution);
    return (
      <div className={"kat-row"}>
        <div className={"kat-col-xs-12 kat-col-md-4 kat-row"}>
          <div className={"kat-col-xs-8 kat-col-xl-7"}>
            <p>
              <b>{t("workflow-start-date")}</b>: {workflowExecution.startDate.toUTCString()}
            </p>
            {workflowExecution.status != WorkflowStatus.RUNNING && (
              <p>
                <b>{t("workflow-stop-date")}</b>: {workflowExecution.stopDate.toUTCString()}
              </p>
            )}
            <p className={styles.consoleLink}>
              {AuthConfig.isAdmin() && (
                <KatLink href={workflowInfo.redirectLink} label={t("workflow-redirect-aws")} variant="link" target="_blank" />
              )}
              <br />
              {AuthConfig.isAdmin() && workflowExecution.parentArn && (
                <KatLink
                  className={styles.parentConsoleLink}
                  href={workflowInfo.redirectLinkParent}
                  label={t("workflow-redirect-parent")}
                  variant="link"
                  target="_blank"
                />
              )}
              <br />
              {workflowExecution.parentArn && (
                <KatLink
                  className={styles.parentConsoleLink}
                  label={t("workflow-open-parent")}
                  variant="link"
                  onClick={() => openParentAccordion(workflowInfo.parentID)}
                />
              )}
            </p>
          </div>
          <div className={"kat-col-xs-4 kat-col-xl-5"}>
            {AuthConfig.isAdmin() && REDRIVABLE_STATUS_LIST.includes(workflowExecution.status) && (
              <KatButton
                value={workflowExecution.executionArn}
                onClick={onRedriveButtonPressed}
                id={`redriveButton-${workflowInfo.workflowID}`}
              >
                {workflowExecution.status === WorkflowStatus.RUNNING ? t("workflow-redrive-button-running") : t("workflow-redrive-button")}
              </KatButton>
            )}
          </div>
        </div>
        <div className={"kat-col-xs-12 kat-col-sm-6 kat-col-md-4"}>
          <KatAccordion>
            <KatAccordionItem label={t("workflow-show-input")} remainsExpanded={true}>
              <ReactJson src={JSON.parse(workflowExecution.input)} enableClipboard={false} displayDataTypes={false} />
            </KatAccordionItem>
          </KatAccordion>
        </div>
        <div className={"kat-col-xs-12 kat-col-sm-6 kat-col-md-4"}>
          {workflowExecution.status != WorkflowStatus.RUNNING && (
            <KatAccordion>
              <KatAccordionItem label={t(workflowInfo.workflowOutputLabel)} remainsExpanded={true}>
                <ReactJson src={workflowInfo.workflowOutput} enableClipboard={false} displayDataTypes={false} />
              </KatAccordionItem>
            </KatAccordion>
          )}
        </div>
      </div>
    );
  };

  const generateAccordion = () => {
    const orderedWorkflow: Array<WorkflowExecution> = workflowResult.data.slice().reverse();
    return (
      <div className={"kat-col-xs-12"}>
        <KatAccordion>
          {orderedWorkflow.map((workflowExecution) => {
            const workflowID = `${workflowExecution.workflowName}:${workflowExecution.executionArn.split(":").pop()}`;
            return (
              <KatAccordionItem
                id={workflowID}
                label={getLabel(workflowExecution)}
                key={workflowID}
                badge={getBadge(workflowExecution)}
                remainsExpanded={true}
                ref={(workflow) => parentWorkflowIdToAccordionRef.current.set(workflowID, workflow)}
              >
                {generateBody(workflowExecution)}
              </KatAccordionItem>
            );
          })}
        </KatAccordion>
      </div>
    );
  };

  const selectMessageToShow = () => {
    if (props.loading) {
      return t("loading-workflows");
    }
    if (!props.showResults || (!workflowResult.data && !workflowResult.error)) {
      return null;
    }
    if (workflowResult.error) {
      return workflowResult.error;
    }
    if (workflowResult.data.length === 0) {
      return t("workflow-missing-message", { customerID: props.customerID });
    }
    return generateAccordion();
  };

  return (
    <ResizableBox showResults={props.showResults}>
      <h3>{t("workflow-execution-header")}</h3>
      <div className={"kat-row"}>{selectMessageToShow()}</div>
      <RedriveModal
        visible={showModal}
        executionARN={arnToRedrive}
        updateVisible={updateShowModal}
        realm={props.realm}
        addRedrivenExecution={addRedrivenExecution}
      />
    </ResizableBox>
  );
};
