import React, { Component, ReactNode } from "react";
import { Route, withRouter, RouteComponentProps, Switch } from "react-router-dom";
import SearchHome from "./Components/SearchHome/SearchHome";
import RedriveHome from "./Components/RedriveHome/RedriveHome";
import PartnershipHome from "./Components/PartnershipHome/PartnershipHome";
import KeyMasterHome from "./Components/KeyMasterHome/KeyMasterHome";

export class App extends Component<RouteComponentProps> {
  private useQuery() {
    return new URLSearchParams(this.props.location.search);
  }

  private formatBooleanParameter(boolParam: string): boolean {
    switch (boolParam) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return null;
    }
  }

  render(): ReactNode {
    const query = this.useQuery();
    return (
      <Switch>
        <Route path="/search">
          <SearchHome customerID={query.get("customerID")} marketplace={query.get("marketplace")} />
        </Route>
        <Route path="/redrive">
          <RedriveHome
            realm={query.get("realm")}
            stateMachineName={query.get("stateMachineName")}
            override={this.formatBooleanParameter(query.get("override"))}
            resume={this.formatBooleanParameter(query.get("resume"))}
            redriveFailed={this.formatBooleanParameter(query.get("redriveFailed"))}
            redriveAborted={this.formatBooleanParameter(query.get("redriveAborted"))}
            redriveTimedOut={this.formatBooleanParameter(query.get("redriveTimedOut"))}
            redriveRunning={this.formatBooleanParameter(query.get("redriveRunning"))}
            startDate={query.get("startDate")}
            endDate={query.get("endDate")}
          />
        </Route>
        <Route path="/partnership">
          <PartnershipHome />
        </Route>
        <Route path="/keymaster">
          <KeyMasterHome />
        </Route>
        <Route path="/">
          <SearchHome />
        </Route>
      </Switch>
    );
  }
}

export default withRouter(App);
