class Marketplace {
  public marketplaceIdForDevo: string;
  public marketplaceIdForProd: string;
  public realm: string;
  public id: string;
  public icon: string;

  constructor(marketplaceIdForDevo, marketplaceIdForProd, realm, id, icon) {
    this.marketplaceIdForDevo = marketplaceIdForDevo;
    this.marketplaceIdForProd = marketplaceIdForProd;
    this.realm = realm;
    this.id = id;
    this.icon = icon;
  }
}

const DE = new Marketplace("A1PA6795UKMFR9", "A1PA6795UKMFR9", "EU", "DE", "flag-de");
const IN = new Marketplace("A2XZLSVIQ0F4JT", "A21TJRUUN4KGV", "EU", "IN", "flag-in");
const IT = new Marketplace("A3HOBANJMCMD83", "APJ6JRA9NG5V4", "EU", "IT", "flag-it");
const ES = new Marketplace("AJZF8LZ1EJVJN", "A1RKKUPIHCS9HS", "EU", "ES", "flag-es");
const FR = new Marketplace("A13V1IB3VIYZZH", "A13V1IB3VIYZZH", "EU", "FR", "flag-fr");
const UK = new Marketplace("A1F83G8C2ARO7P", "A1F83G8C2ARO7P", "EU", "UK", "flag-uk");
const US = new Marketplace("ATVPDKIKX0DER", "ATVPDKIKX0DER", "NA", "US", "flag-us");
const CA = new Marketplace("A2EUQ1WTGCTBG2", "A2EUQ1WTGCTBG2", "NA", "CA", "flag-ca");
const MX = new Marketplace("A3P3J5A7D2ZVXI", "A1AM78C64UM0Y8", "NA", "MX", "flag-mx");
const JP = new Marketplace("A1VC38T7YXB528", "A1VC38T7YXB528", "FE", "JP", "flag-jp");

export const marketplaceInfo = {
  IN: IN,
  DE: DE,
  IT: IT,
  ES: ES,
  FR: FR,
  UK: UK,
  US: US,
  CA: CA,
  MX: MX,
  JP: JP,
};

export const marketplacesByRealm = {
  EU: [DE, IN, IT, ES, FR, UK],
  NA: [US, CA, MX],
  FE: [JP],
};
