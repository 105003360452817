import React from "react";
import { useTranslation } from "react-i18next";
import { KatButton } from "@amzn/katal-react";
import { KatModal } from "@amzn/katal-react";

export interface CancelModalProps {
  isCancellationModalShown: boolean;
  customerId: string;
  marketplaceId: string;
  onSubmit: () => void;
  onExit: () => void;
}

export const CancelModal: React.FC<CancelModalProps> = (props) => {
  const { t } = useTranslation();

  const cancelButtonId = "cancelButtonId";
  const submitButtonId = "submitButtonId";

  return (
    <KatModal visible={props.isCancellationModalShown} onClose={props.onExit}>
      <span slot="title">{t("modal-cancel-title")}</span>
      <p className="text-size-md">
        <p>{t("modal-cancel-description")}</p>
        <p>
          {t("modal-cancel-customer")} <b>{props.customerId}</b>
        </p>
        <p>
          {t("modal-cancel-marketplace")} <b>{props.marketplaceId}</b>
        </p>
      </p>
      <div slot="footer" className="kat-group-horizontal">
        <KatButton id={cancelButtonId} variant="secondary" onClick={props.onExit}>
          {t("modal-cancel-no")}
        </KatButton>
        <KatButton id={submitButtonId} variant="danger" onClick={props.onSubmit}>
          {t("modal-cancel-yes")}
        </KatButton>
      </div>
    </KatModal>
  );
};
