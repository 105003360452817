import React, { ReactElement, useEffect, useState } from "react";
import styles from "./DiffBox.module.css";
import { useTranslation } from "react-i18next";
import { ResizableBox } from "../ResizableBox/ResizableBox";
import { KatAccordion, KatAccordionItem, KatBox, KatButton, KatDropdown, KatIcon, KatSpinner, KatTab, KatTabs } from "@amzn/katal-react";
import { JSONObject, SelectedDiff, TableHistoryResult, TableResult } from "../../common/Types";
import { DiffView } from "../DiffView/DiffView";

type DiffBoxProps = {
  loading: boolean;
  loadingDiff: boolean;
  diffLoaded: boolean;
  registrationFormDataHistoryResult: TableHistoryResult;
  registrationRequestHistoryResult: TableHistoryResult;
  abRegistrationHistoryResult: TableHistoryResult;
  registrationFormDataResult: TableResult;
  registrationRequestResult: TableResult;
  abRegistrationResult: TableResult;
  onDiffFormAccordionExpanded: () => void;
  onLoadHistoryResultButtonPressed: () => void;
  showResults: boolean;
};

type DropdownOption = {
  name: string;
  value: string;
};

type DropdownOptions = Array<DropdownOption>;

type StateSettersProps = {
  updateSelectedDiffRegistrationFormData: React.Dispatch<React.SetStateAction<SelectedDiff>>;
  updateSelectedDiffRegistrationRequest: React.Dispatch<React.SetStateAction<SelectedDiff>>;
  updateSelectedDiffABRegistration: React.Dispatch<React.SetStateAction<SelectedDiff>>;
  updateDropdownOptionsRegistrationFormData: React.Dispatch<React.SetStateAction<DropdownOptions>>;
  updateDropdownOptionsRegistrationRequest: React.Dispatch<React.SetStateAction<DropdownOptions>>;
  updateDropdownOptionsABRegistration: React.Dispatch<React.SetStateAction<DropdownOptions>>;
};
enum DiffSide {
  left,
  right,
}

enum TablesIds {
  registrationFormData = "RegistrationFormData",
  registrationRequest = "RegistrationRequest",
  abRegistration = "ABRegistration",
}

const REMOVE_ACTION = "REMOVE";
const JSON_FOR_REMOVE_ACTION = JSON.stringify({});

const getTimestampForHistory = (historyCreatedTime: string): string => {
  const [date, time] = historyCreatedTime.split(" ");
  const [year, month, day] = date.split("-").map((stringValue: string) => parseInt(stringValue));
  const [hours, minutes, seconds, milliseconds] = time.split(":").map((stringValue: string) => parseInt(stringValue));
  const dateObject = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds, milliseconds));
  return dateObject.toISOString();
};

const getTableIdentifierForLatest = (tableData: JSONObject, tableId: TablesIds): string => {
  let dateString;
  switch (tableId) {
    case TablesIds.registrationFormData:
      dateString = tableData.updatedDateTime;
      break;
    case TablesIds.registrationRequest:
      dateString = tableData.updatedTime;
      break;
    case TablesIds.abRegistration:
      dateString = tableData.modificationDate;
      break;
  }
  return dateString ? `${dateString} - LATEST` : "LATEST";
};

const getTableVersionIdentifier = (historyResult: JSONObject, tableId: TablesIds): string => {
  return tableId === TablesIds.registrationFormData
    ? `${historyResult.historyCreatedTime} - ${historyResult.actionPerformed}`
    : `${getTimestampForHistory(historyResult.historyCreatedTime as string)} - ${historyResult.actionPerformed}`;
};

const formatHistoryEntryIfDelete = (historyResult: JSONObject): string => {
  return historyResult.actionPerformed === REMOVE_ACTION ? JSON_FOR_REMOVE_ACTION : JSON.stringify(historyResult);
};

const getOptionsForDropdown = (tableResult: TableResult, tableHistoryResult: TableHistoryResult, tableId: TablesIds): DropdownOptions => {
  const optionsList = [];
  if (!tableResult.error) {
    optionsList.push({
      name: getTableIdentifierForLatest(tableResult.data, tableId),
      value: JSON.stringify(tableResult.data),
    });
  }
  tableHistoryResult.data
    .slice()
    .reverse()
    .forEach((historyResult) =>
      optionsList.push({
        name: getTableVersionIdentifier(historyResult, tableId),
        value: formatHistoryEntryIfDelete(historyResult),
      })
    );
  return optionsList;
};

const getDefaultValueForDiffRight = (tableResult: TableResult, tableHistoryResult: TableHistoryResult): string => {
  return !tableResult.error ? JSON.stringify(tableResult.data) : formatHistoryEntryIfDelete(tableHistoryResult.data.slice().reverse()[0]);
};

const getDefaultValueForDiffLeft = (tableHistoryResult: TableHistoryResult): string => {
  const tableHistoryResultReverted = tableHistoryResult.data.slice().reverse();
  return tableHistoryResultReverted.length > 1
    ? formatHistoryEntryIfDelete(tableHistoryResultReverted[1])
    : formatHistoryEntryIfDelete(tableHistoryResultReverted[0]);
};

const isRightMoveDisabled = (selectedDiff: SelectedDiff, dropdownOptions: DropdownOptions): boolean => {
  return selectedDiff && selectedDiff.left === dropdownOptions[dropdownOptions.length - 1].value;
};

const isLeftMoveDisabled = (selectedDiff: SelectedDiff, dropdownOptions: DropdownOptions): boolean => {
  return selectedDiff && selectedDiff.right === dropdownOptions[0].value;
};

export const setInitialDataEffect = (props: DiffBoxProps, stateSettersProps: StateSettersProps): void => {
  useEffect(() => {
    if (!props.diffLoaded) {
      return;
    }
    if (!props.registrationFormDataHistoryResult.error) {
      stateSettersProps.updateSelectedDiffRegistrationFormData({
        left: getDefaultValueForDiffLeft(props.registrationFormDataHistoryResult),
        right: getDefaultValueForDiffRight(props.registrationFormDataResult, props.registrationFormDataHistoryResult),
      });
      stateSettersProps.updateDropdownOptionsRegistrationFormData(
        getOptionsForDropdown(props.registrationFormDataResult, props.registrationFormDataHistoryResult, TablesIds.registrationFormData)
      );
    }
    if (!props.registrationRequestHistoryResult.error) {
      stateSettersProps.updateSelectedDiffRegistrationRequest({
        left: getDefaultValueForDiffLeft(props.registrationRequestHistoryResult),
        right: getDefaultValueForDiffRight(props.registrationRequestResult, props.registrationRequestHistoryResult),
      });
      stateSettersProps.updateDropdownOptionsRegistrationRequest(
        getOptionsForDropdown(props.registrationRequestResult, props.registrationRequestHistoryResult, TablesIds.registrationRequest)
      );
    }
    if (!props.abRegistrationHistoryResult.error) {
      stateSettersProps.updateSelectedDiffABRegistration({
        left: getDefaultValueForDiffLeft(props.abRegistrationHistoryResult),
        right: getDefaultValueForDiffRight(props.abRegistrationResult, props.abRegistrationHistoryResult),
      });
      stateSettersProps.updateDropdownOptionsABRegistration(
        getOptionsForDropdown(props.abRegistrationResult, props.abRegistrationHistoryResult, TablesIds.abRegistration)
      );
    }
  }, [props.diffLoaded]);
};

export const DiffBox: React.FC<DiffBoxProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const [selectedDiffRegistrationFormData, updateSelectedDiffRegistrationFormData] = useState<SelectedDiff>(null);
  const [selectedDiffRegistrationRequest, updateSelectedDiffRegistrationRequest] = useState<SelectedDiff>(null);
  const [selectedDiffABRegistration, updateSelectedDiffABRegistration] = useState<SelectedDiff>(null);
  const [dropdownOptionsRegistrationFormData, updateDropdownOptionsRegistrationFormData] = useState<DropdownOptions>(null);
  const [dropdownOptionsRegistrationRequest, updateDropdownOptionsRegistrationRequest] = useState<DropdownOptions>(null);
  const [dropdownOptionsABRegistration, updateDropdownOptionsABRegistration] = useState<DropdownOptions>(null);

  setInitialDataEffect(props, {
    updateSelectedDiffRegistrationFormData,
    updateSelectedDiffRegistrationRequest,
    updateSelectedDiffABRegistration,
    updateDropdownOptionsRegistrationFormData,
    updateDropdownOptionsRegistrationRequest,
    updateDropdownOptionsABRegistration,
  });

  const updateSelectedDiff = (newDiff: SelectedDiff, tableToChange: TablesIds) => {
    switch (tableToChange) {
      case TablesIds.abRegistration:
        updateSelectedDiffABRegistration(newDiff);
        break;
      case TablesIds.registrationFormData:
        updateSelectedDiffRegistrationFormData(newDiff);
        break;
      case TablesIds.registrationRequest:
        updateSelectedDiffRegistrationRequest(newDiff);
        break;
    }
  };

  const moveDiffLeft = (tableToChange: TablesIds) => {
    const dropdownOptions = getDropdownOptions(tableToChange);
    const selectedDiff = getDiff(tableToChange);
    const selectedElementIndex = dropdownOptions.findIndex((dropdownOption: DropdownOption) => dropdownOption.value === selectedDiff.right);
    const newDiff: SelectedDiff = {
      left: selectedDiff.right,
      right: dropdownOptions[selectedElementIndex - 1].value,
    };
    updateSelectedDiff(newDiff, tableToChange);
  };

  const moveDiffRight = (tableToChange: TablesIds) => {
    const dropdownOptions = getDropdownOptions(tableToChange);
    const selectedDiff = getDiff(tableToChange);
    const selectedElementIndex = dropdownOptions.findIndex((dropdownOption: DropdownOption) => dropdownOption.value === selectedDiff.left);
    const newDiff: SelectedDiff = {
      left: dropdownOptions[selectedElementIndex + 1].value,
      right: selectedDiff.left,
    };
    updateSelectedDiff(newDiff, tableToChange);
  };

  const updateDiff = (newResult: string, tableToChange: TablesIds, sideToChange: DiffSide) => {
    let oldDiff: SelectedDiff;
    switch (tableToChange) {
      case TablesIds.abRegistration:
        oldDiff = selectedDiffABRegistration;
        break;
      case TablesIds.registrationFormData:
        oldDiff = selectedDiffRegistrationFormData;
        break;
      case TablesIds.registrationRequest:
        oldDiff = selectedDiffRegistrationRequest;
        break;
    }
    let newDiff: SelectedDiff;
    switch (sideToChange) {
      case DiffSide.left:
        newDiff = {
          left: newResult,
          right: oldDiff.right,
        };
        break;
      case DiffSide.right:
        newDiff = {
          left: oldDiff.left,
          right: newResult,
        };
        break;
    }
    updateSelectedDiff(newDiff, tableToChange);
  };

  const getDiff = (tableId: TablesIds) => {
    switch (tableId) {
      case TablesIds.registrationRequest:
        return selectedDiffRegistrationRequest;
      case TablesIds.registrationFormData:
        return selectedDiffRegistrationFormData;
      case TablesIds.abRegistration:
        return selectedDiffABRegistration;
    }
  };

  const getDropdownOptions = (tableId: TablesIds) => {
    switch (tableId) {
      case TablesIds.registrationRequest:
        return dropdownOptionsRegistrationRequest;
      case TablesIds.registrationFormData:
        return dropdownOptionsRegistrationFormData;
      case TablesIds.abRegistration:
        return dropdownOptionsABRegistration;
    }
  };

  const printTabContent = (tableResult: TableResult, tableHistoryResult: TableHistoryResult, tableId: TablesIds) => {
    if (!props.diffLoaded) {
      return null;
    }
    return tableHistoryResult.error ? (
      tableHistoryResult.error
    ) : (
      <div className={`kat-row kat-col-xs-12 ${styles.diffBoxTabContent}`}>
        <DiffView selectedDiff={getDiff(tableId)} />
        <div className={"kat-col-xs-12 kat-row"}>
          <KatDropdown
            id={`diffLeftDropDown${tableId}`}
            label={t("diff-view-dropdown-left")}
            className={"kat-col-xs-6"}
            options={getDropdownOptions(tableId)}
            value={getDiff(tableId) && getDiff(tableId).left}
            onChange={(event: KatDropdown.ChangeEvent) => updateDiff(event.detail.value, tableId, DiffSide.left)}
            searchable={true}
          />
          <KatDropdown
            id={`diffRightDropDown${tableId}`}
            label={t("diff-view-dropdown-right")}
            className={"kat-col-xs-6"}
            options={getDropdownOptions(tableId)}
            value={getDiff(tableId) && getDiff(tableId).right}
            onChange={(event: KatDropdown.ChangeEvent) => updateDiff(event.detail.value, tableId, DiffSide.right)}
            searchable={true}
          />
        </div>
        <div className={"kat-col-xs-12 kat-row"}>
          <KatButton
            id={`moveLeftHistoryButton${tableId}`}
            className={`${styles.moveHistoryButtons} kat-offset-xs-2 kat-col-xs-4`}
            variant="secondary"
            disabled={isLeftMoveDisabled(getDiff(tableId), getDropdownOptions(tableId))}
            onClick={() => moveDiffLeft(tableId)}
          >
            <KatIcon id={`moveLeftHistoryButtonIcon${tableId}`} name={"fast_rewind"} size="small" slot="icon" />
            <div>{t("diff-move-left")}</div>
          </KatButton>
          <KatButton
            id={`moveRightHistoryButton${tableId}`}
            className={`${styles.moveHistoryButtons} kat-col-xs-4`}
            variant="secondary"
            disabled={isRightMoveDisabled(getDiff(tableId), getDropdownOptions(tableId))}
            onClick={() => moveDiffRight(tableId)}
          >
            <KatIcon id={`moveRightHistoryButtonIcon${tableId}`} name={"fast_forward"} size="small" slot="icon" />
            <div>{t("diff-move-right")}</div>
          </KatButton>
        </div>
      </div>
    );
  };

  const showDiffTabs = () => {
    return (
      <div className={"kat-row"}>
        <KatTabs selected={TablesIds.registrationFormData} id="historyResultTabs" className={"kat-col-xs-12"}>
          <KatTab label={TablesIds.registrationFormData} tabId={TablesIds.registrationFormData}>
            <KatBox variant="white-shadow">
              {printTabContent(props.registrationFormDataResult, props.registrationFormDataHistoryResult, TablesIds.registrationFormData)}
            </KatBox>
          </KatTab>
          <KatTab label={TablesIds.registrationRequest} tabId={TablesIds.registrationRequest}>
            <KatBox variant="white-shadow">
              {printTabContent(props.registrationRequestResult, props.registrationRequestHistoryResult, TablesIds.registrationRequest)}
            </KatBox>
          </KatTab>
          <KatTab label={TablesIds.abRegistration} tabId={TablesIds.abRegistration}>
            <KatBox variant="white-shadow">
              {printTabContent(props.abRegistrationResult, props.abRegistrationHistoryResult, TablesIds.abRegistration)}
            </KatBox>
          </KatTab>
        </KatTabs>
        <div className={"kat-col-xs-12 kat-row"}>
          <KatButton
            id="loadHistoryResultButton"
            onClick={props.onLoadHistoryResultButtonPressed}
            className={`${styles.loadHistoryButton} kat-offset-xs-4 kat-col-xs-4`}
            variant="primary"
          >
            <KatIcon id={"loadHistoryResultButtonIcon"} name={"refresh"} size="small" slot="icon" />
            <div>{t("diff-load-history")}</div>
          </KatButton>
        </div>
      </div>
    );
  };

  const showAccordionBody = () => {
    return props.loadingDiff ? (
      <div className={"kat-row"}>
        <KatSpinner className={`${styles.loadingSpinner} kat-offset-xs-4 kat-col-xs-4`} />
      </div>
    ) : (
      showDiffTabs()
    );
  };

  return (
    <ResizableBox showResults={props.showResults}>
      <h3>{t("diff-box-header")}</h3>
      {props.loading ? (
        t("diff-box-loading")
      ) : (
        <KatAccordion>
          <KatAccordionItem label={t("diff-box-accordion-header")} onExpand={props.onDiffFormAccordionExpanded}>
            {showAccordionBody()}
          </KatAccordionItem>
        </KatAccordion>
      )}
    </ResizableBox>
  );
};
