import React, { ReactElement, useEffect, useState } from "react";
import { AuthConfig } from "../../lib/AuthConfig";
import {
  KatAccordion,
  KatAccordionItem,
  KatButton,
  KatDivider,
  KatIcon,
  KatLink,
  KatSpinner,
  KatTable,
  KatTableBody,
  KatTableCell,
  KatTableHead,
  KatTableRow,
} from "@amzn/katal-react";
import ReactJson from "react-json-view";
import styles from "./RedriveResultBox.module.css";
import { useTranslation } from "react-i18next";
import {
  RedriveError,
  WorkflowBatchRedrive,
  WorkflowBatchRedrivePoll,
  WorkflowBatchRedrivePollResult,
  WorkflowBatchRedriveStarted,
  WorkflowBatchRedriveStartedResult,
  WorkflowError,
  WorkflowExecution,
} from "../../common/Types";
import { ResizableBox } from "../ResizableBox/ResizableBox";
import { WorkflowStatus } from "../../common/Constants";
import { getBadge, getLabel, getRedirectLinkFromArn, getRedirectToLink, getWorkflowInfo, WorkflowInfo } from "../../utils/WorkflowUtils";
import { ACCOUNT_ID_FOR_STAGE } from "../../common/AccountData";

type RedriveResultBoxProps = {
  stage: string;
  redriveHappenedRealm: string;
  startLoading: boolean;
  polling: boolean;
  pollingStopping: boolean;
  pollingStopped: boolean;
  showResults: boolean;
  redrivePollResult: WorkflowBatchRedrivePollResult;
  redriveStartedResult: WorkflowBatchRedriveStartedResult;
  onCancelPollingPressed: () => void;
  onStartPollingPressed: (executionArn: string, logGroupName: string, logStreamName: string) => void;
};

export const RedriveResultBox: React.FC<RedriveResultBoxProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const [redrivePollResult, updateRedrivePollResult] = useState<WorkflowBatchRedrivePollResult>(props.redrivePollResult);

  useEffect(() => {
    updateRedrivePollResult(props.redrivePollResult);
  }, [props.redrivePollResult]);

  const generateFailedBody = (redriveError: RedriveError) => {
    const redirectLinkParent = getRedirectLinkFromArn(redriveError.parentFailedExecution);
    return (
      <div className={"kat-row"}>
        <div className={"kat-col-xs-4"}>
          {AuthConfig.isAdmin() && (
            <KatLink
              className={styles.parentConsoleLink}
              href={redirectLinkParent}
              label={t("workflow-redirect-parent")}
              variant="link"
              target="_blank"
            />
          )}
        </div>
        <div className={"kat-col-xs-8"}>
          <KatAccordion>
            <KatAccordionItem label={"Show exception"} remainsExpanded={true} className={styles.redriveError}>
              {redriveError.error.errorMessage}
            </KatAccordionItem>
          </KatAccordion>
        </div>
      </div>
    );
  };

  const generateFailedAccordion = (redriveErrors: Array<RedriveError>) => {
    return (
      <div>
        <KatAccordion>
          {redriveErrors.map((redriveError) => {
            return (
              <KatAccordionItem
                id={redriveError.parentFailedExecution}
                label={redriveError.parentFailedExecution}
                key={redriveError.parentFailedExecution}
                remainsExpanded={true}
              >
                {generateFailedBody(redriveError)}
              </KatAccordionItem>
            );
          })}
        </KatAccordion>
      </div>
    );
  };

  const generateBody = (workflowExecution: WorkflowExecution) => {
    const workflowInfo: WorkflowInfo = getWorkflowInfo(workflowExecution);
    return (
      <div className={"kat-row"}>
        <div className={"kat-col-xs-12 kat-col-md-4 kat-row"}>
          <div className={"kat-col-xs-8 kat-col-xl-7"}>
            <p>
              <b>{t("workflow-start-date")}</b>: {workflowExecution.startDate.toUTCString()}
            </p>
            {workflowExecution.status != WorkflowStatus.RUNNING && (
              <p>
                <b>{t("workflow-stop-date")}</b>: {workflowExecution.stopDate.toUTCString()}
              </p>
            )}
            <p className={styles.consoleLink}>
              {AuthConfig.isAdmin() && (
                <KatLink href={workflowInfo.redirectLink} label={t("workflow-redirect-aws")} variant="link" target="_blank" />
              )}
              <br />
              {AuthConfig.isAdmin() && workflowExecution.parentArn && (
                <KatLink
                  className={styles.parentConsoleLink}
                  href={workflowInfo.redirectLinkParent}
                  label={t("workflow-redirect-parent")}
                  variant="link"
                  target="_blank"
                />
              )}
              <br />
            </p>
          </div>
        </div>
        <div className={"kat-col-xs-12 kat-col-sm-6 kat-col-md-4"}>
          <KatAccordion>
            <KatAccordionItem label={t("workflow-show-input")} remainsExpanded={true}>
              <ReactJson src={JSON.parse(workflowExecution.input)} enableClipboard={false} displayDataTypes={false} />
            </KatAccordionItem>
          </KatAccordion>
        </div>
        <div className={"kat-col-xs-12 kat-col-sm-6 kat-col-md-4"}>
          {workflowExecution.status != WorkflowStatus.RUNNING && (
            <KatAccordion>
              <KatAccordionItem label={t(workflowInfo.workflowOutputLabel)} remainsExpanded={true}>
                <ReactJson src={workflowInfo.workflowOutput} enableClipboard={false} displayDataTypes={false} />
              </KatAccordionItem>
            </KatAccordion>
          )}
        </div>
      </div>
    );
  };

  const showRedriveResult = (redriveByDatesResult: WorkflowBatchRedrive) => {
    const orderedWorkflow: Array<WorkflowExecution> = redriveByDatesResult.newExecutionDescriptions
      .slice()
      .sort((execution1, execution2) => execution2.startDate.valueOf() - execution1.startDate.valueOf());
    return (
      <KatAccordion className={styles.bottomSpace}>
        <KatAccordionItem label={t("workflow-redrive-success-header")} remainsExpanded={true} expanded={true}>
          <div className={"kat-col-xs-12"}>
            <h4>{t("workflow-redrive-box-info-header")}</h4>
            <div className={"kat-col-xs-12"}>
              <h6>
                <p id={"attemptedRedrives"}>
                  <b>{t("workflow-redrive-box-attempted")}</b>: {redriveByDatesResult.attemptedRedrives}
                </p>
              </h6>
              <h6>
                <p id={"successfullyLaunched"}>
                  <b>{t("workflow-redrive-box-success")}</b>: {redriveByDatesResult.successfullyLaunched}
                </p>
              </h6>
              <h6>
                <p id={"failedToLaunch"}>
                  <b>{t("workflow-redrive-box-failed")}</b>: {redriveByDatesResult.failedToLaunch}
                </p>
              </h6>
            </div>
            <KatDivider variant={"eastern"} />
            <h4>{t("workflow-redrive-box-success")}</h4>
            <div className={`kat-col-xs-12 ${styles.accordionMargin}`}>
              <KatAccordion>
                {orderedWorkflow.map((workflowExecution) => {
                  const workflowID = `${workflowExecution.workflowName}:${workflowExecution.executionArn.split(":").pop()}`;
                  return (
                    <KatAccordionItem
                      id={workflowID}
                      label={getLabel(workflowExecution)}
                      key={workflowID}
                      badge={getBadge(workflowExecution)}
                      remainsExpanded={true}
                    >
                      {generateBody(workflowExecution)}
                    </KatAccordionItem>
                  );
                })}
              </KatAccordion>
            </div>
            <KatDivider variant={"eastern"} />
            <h4>{t("workflow-redrive-box-failed-header")}</h4>
            <div className={"kat-col-xs-12"}>{generateFailedAccordion(redriveByDatesResult.redriveErrors)}</div>
          </div>
        </KatAccordionItem>
      </KatAccordion>
    );
  };

  const showRedriveStartedResult = (redriveStartedResult: WorkflowBatchRedriveStarted) => {
    const redirectLink = getRedirectLinkFromArn(redriveStartedResult.executionArn);
    return (
      <div className={"kat-col-xs-12"}>
        <p>{t("workflow-redrives-started")}</p>
        {AuthConfig.isAdmin() && <KatLink href={redirectLink} label={t("workflow-redirect-aws-redriven")} variant="link" target="_blank" />}
        <KatDivider variant={"eastern"} />
      </div>
    );
  };

  const showError = (workflowError: WorkflowError) => {
    return (
      <KatAccordion className={styles.bottomSpace}>
        <KatAccordionItem label={t("workflow-redrive-error-header")} remainsExpanded={true} expanded={true}>
          <ResizableBox showResults={true}>
            <ReactJson src={workflowError} enableClipboard={false} displayDataTypes={false} />
          </ResizableBox>
        </KatAccordionItem>
      </KatAccordion>
    );
  };

  const showLogsAndResult = (redrivePollResult: WorkflowBatchRedrivePoll) => {
    const logsLink = `https://${props.redriveHappenedRealm}.console.aws.amazon.com/cloudwatch/home${encodeURIComponent(
      `?region=${props.redriveHappenedRealm}#`
    )}logsV2:log-groups/log-group/${props.redriveStartedResult.data.logGroupName}/log-events/${
      props.redriveStartedResult.data.logStreamName
    }`;
    const redirectLink = getRedirectToLink(ACCOUNT_ID_FOR_STAGE[props.stage], logsLink);
    return (
      <div className={"kat-col-xs-12"}>
        {redrivePollResult.workflowError
          ? showError(redrivePollResult.workflowError)
          : redrivePollResult.redriveByDatesResult
          ? showRedriveResult(redrivePollResult.redriveByDatesResult)
          : null}
        <KatAccordion>
          <KatAccordionItem label={t("workflow-show-logs")} remainsExpanded={true} expanded={true}>
            {AuthConfig.isAdmin() && (
              <p>
                <KatLink href={redirectLink} label={t("workflow-redirect-aws-logs")} variant="link" target="_blank" />
              </p>
            )}
            <KatTable>
              <KatTableHead>
                <KatTableRow>
                  <KatTableCell className={styles.timestampWidth}>{t("workflow-log-timestamp")}</KatTableCell>
                  <KatTableCell>{t("workflow-log-message")}</KatTableCell>
                </KatTableRow>
              </KatTableHead>
              <KatTableBody>
                {redrivePollResult.logEvents.map((logEvent, index) => {
                  return (
                    <KatTableRow key={index}>
                      <KatTableCell className={styles.timestampWidth}>{logEvent.timestamp.toUTCString()}</KatTableCell>
                      <KatTableCell>
                        <pre className={styles.messageStyle}>{logEvent.message}</pre>
                      </KatTableCell>
                    </KatTableRow>
                  );
                })}
              </KatTableBody>
            </KatTable>
          </KatAccordionItem>
        </KatAccordion>
      </div>
    );
  };

  const selectStartMessageToShow = () => {
    if (props.startLoading) {
      return t("loading-workflows-redrive");
    }
    if (!props.showResults || (!props.redriveStartedResult.data && !props.redriveStartedResult.error)) {
      return null;
    }
    if (props.redriveStartedResult.error) {
      return (
        <div className={`kat-col-xs-12 ${styles.recoverBottomSpace}`} id={"redriveStartedError"}>
          <p>{t("workflow-redrive-start-failed")}</p>
          <p>{props.redriveStartedResult.error}</p>
        </div>
      );
    }
    return showRedriveStartedResult(props.redriveStartedResult.data);
  };

  const selectRedriveMessageToShow = () => {
    if (!props.showResults || (!redrivePollResult.data && !redrivePollResult.error) || props.startLoading) {
      return null;
    }
    if (redrivePollResult.error) {
      return (
        <div className={"kat-col-xs-12"} id={"redrivePollError"}>
          <KatDivider variant={"eastern"} />
          <p>{t("workflow-redrive-poll-failed")}</p>
          <p>{redrivePollResult.error}</p>
          <KatDivider variant={"eastern"} />
        </div>
      );
    }
    return showLogsAndResult(redrivePollResult.data);
  };

  const pollingRow = () => {
    if (!props.showResults || props.startLoading || props.redriveStartedResult.error) {
      return null;
    }
    const [executionArn, logGroupName, logStreamName] = [
      props.redriveStartedResult.data.executionArn,
      props.redriveStartedResult.data.logGroupName,
      props.redriveStartedResult.data.logStreamName,
    ];
    return (
      <div className={"kat-col-xs-12 kat-row"}>
        <KatButton
          id={"cancelPolling"}
          variant={"secondary"}
          disabled={props.pollingStopped || props.pollingStopping}
          className={"kat-col-xs-4"}
          onClick={props.onCancelPollingPressed}
        >
          <KatIcon name={"block"} size="small" slot="icon" /> <div>{t("workflow-redrive-poll-stop")}</div>
        </KatButton>
        <KatButton
          id={"startPolling"}
          variant={"tertiary"}
          disabled={!props.pollingStopped}
          className={"kat-col-xs-4"}
          onClick={() => props.onStartPollingPressed(executionArn, logGroupName, logStreamName)}
        >
          <KatIcon name={"play"} size="small" slot="icon" /> <div>{t("workflow-redrive-poll-start")}</div>
        </KatButton>
        {props.pollingStopping && !props.pollingStopped ? (
          <div className={"kat-col-xs-4"} id={"pollingStoppingSpinner"}>
            <KatSpinner size={"small"} className={styles.verticalAlignPolling} />
            <span className={styles.verticalAlignPolling}>{t("workflow-redrive-poll-stopping-message")}</span>
          </div>
        ) : (
          props.polling && (
            <div className={"kat-col-xs-4"} id={"pollingSpinner"}>
              <KatSpinner size={"small"} className={styles.verticalAlignPolling} />
              <span className={styles.verticalAlignPolling}>{t("workflow-redrive-poll-message")}</span>
            </div>
          )
        )}
      </div>
    );
  };

  return (
    <ResizableBox showResults={props.showResults}>
      <h3>{t("workflow-redrive-box-header")}</h3>
      <div className={`kat-row ${styles.bottomSpace}`} id={"startResultRow"}>
        {selectStartMessageToShow()}
      </div>
      <div className={`kat-row ${styles.bottomSpace}`} id={"pollingRow"}>
        {pollingRow()}
      </div>
      <div className={"kat-row"} id={"redriveResultRow"}>
        {selectRedriveMessageToShow()}
      </div>
    </ResizableBox>
  );
};
