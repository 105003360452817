import React, { ReactElement } from "react";
import { KatButton } from "@amzn/katal-react";
import styles from "./ToolsButtonsBox.module.css";
import { useTranslation } from "react-i18next";
import { ResizableBox } from "../ResizableBox/ResizableBox";

type ToolsButtonsBoxProps = {
  isShowResults: boolean;
  bartonWebsiteHandler: () => void;
  bacHandler: () => void;
  accountExplainerHandler: () => void;
  corvegaHandler: () => void;
};

export const ToolsButtonsBox: React.FC<ToolsButtonsBoxProps> = (props): ReactElement => {
  const { t } = useTranslation();

  const accountExplainerButtonId = "accountExplainer";
  const corvegaButtonId = "corvega";
  const bartonWebsiteButtonId = "bartonWebsite";
  const bacButtonId = "bac";

  const getButton = (label, id, handler) => {
    return <KatButton label={label} id={id} variant="tertiary" onClick={handler} className={styles.resultButton} />;
  };

  return (
    <ResizableBox showResults={props.isShowResults}>
      <div className={styles.resultButtons}>
        <h3>{t("other-tools-header")}</h3>
        {getButton(t("barton-website-button-label"), bartonWebsiteButtonId, props.bartonWebsiteHandler)}
        {getButton(t("bac-button-label"), bacButtonId, props.bacHandler)}
        {getButton(t("account-explainer-button-label"), accountExplainerButtonId, props.accountExplainerHandler)}
        {getButton(t("corvega-button-label"), corvegaButtonId, props.corvegaHandler)}
      </div>
    </ResizableBox>
  );
};
