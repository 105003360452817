import React, { MutableRefObject, ReactElement, useEffect, useRef } from "react";
import { KatBox } from "@amzn/katal-react";
import styles from "./ResizableBox.module.css";
import { newEvent } from "../../backend/dom";

type ResizableBoxProps = {
  showResults: boolean;
  variant?: KatBox.Variant;
};

export const useResize = (boxRef: MutableRefObject<any>, showResults: boolean): void => {
  useEffect(() => {
    if (showResults) {
      boxRef.current.dispatchEvent(newEvent("resize"));
    }
  });
};

export const ResizableBox: React.FC<ResizableBoxProps> = (props): ReactElement => {
  const boxRef = useRef(null);
  useResize(boxRef, props.showResults);

  return (
    props.showResults && (
      <div className={styles.resizableBox}>
        <KatBox ref={boxRef} variant={props.variant}>
          {props.children}
        </KatBox>
      </div>
    )
  );
};

ResizableBox.defaultProps = {
  variant: "white",
};
