export function formatWorkflowExecutionDates(workflowExecutions) {
  const timingKeys = ["startDate", "stopDate"];
  workflowExecutions.forEach((workflowExecution) => {
    for (const timingKey of timingKeys) {
      if (workflowExecution[timingKey] !== undefined) {
        workflowExecution[timingKey] = new Date(workflowExecution[timingKey]);
      }
    }
  });
  return workflowExecutions;
}

export function formatCloudWatchLogDates(logEvents) {
  logEvents.forEach((logEvent) => {
    if (logEvent.timestamp !== undefined) {
      logEvent.timestamp = new Date(logEvent.timestamp);
    }
  });
  return logEvents;
}

export function getErrorMessage(errorData) {
  if (typeof errorData === "string" || errorData instanceof String) {
    errorData = JSON.parse(errorData);
  }
  return errorData.errorMessage;
}

export function stringifyHistoryTable(tableToStringify) {
  return JSON.stringify(
    tableToStringify,
    (key, value) => {
      if (key === "historyCreatedTime" || key === "actionPerformed") {
        return undefined;
      }
      return value;
    },
    2
  );
}
