import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ResizableBox } from "../ResizableBox/ResizableBox";
import { CreatePartnershipInvitationResult } from "../../common/Types";
import ReactJson from "react-json-view";
import { KatBox, KatLink } from "@amzn/katal-react";
import styles from "./PartnershipResultBox.module.css";

type PartnershipResultBoxProps = {
  createInvitationInputString: string;
  createInvitationResult: CreatePartnershipInvitationResult;
  showResults: boolean;
  loading: boolean;
};

export const PartnershipResultBox: React.FC<PartnershipResultBoxProps> = (props): ReactElement => {
  const { t } = useTranslation();

  const showInvitationLink = (invitationLink: string) => {
    return (
      <div className={"kat-col-xs-12 kat-row"}>
        <h4>{t("partnership-result-success")}</h4>
        <div className={`kat-col-xs-12 ${styles.invitationText}`}>
          <p>
            {t("partnership-result-link")} <KatLink href={invitationLink} label={invitationLink} target={"_blank"} />
          </p>
        </div>
      </div>
    );
  };

  const selectResultMessageToShow = () => {
    if (props.loading) {
      return t("partnership-result-loading");
    }
    if (props.createInvitationResult.error) {
      return props.createInvitationResult.error;
    }
    if (props.createInvitationResult.data) {
      return showInvitationLink(props.createInvitationResult.data);
    }
    return null;
  };

  const showInputString = () => {
    return props.loading || !props.showResults ? null : (
      <div className={"kat-col-xs-12 kat-row"}>
        <h5>{t("partnership-result-input")}</h5>
        <div className={"kat-col-xs-12"}>
          <KatBox variant={"white"}>
            <ReactJson src={JSON.parse(props.createInvitationInputString)} displayDataTypes={false} />
          </KatBox>
        </div>
      </div>
    );
  };

  return (
    <ResizableBox showResults={props.showResults}>
      <h3>{t("partnership-result-header")}</h3>
      <div className={"kat-row"}>
        <div className={"kat-col-xs-12 kat-row"} id={"partnershipResultRow"}>
          {selectResultMessageToShow()}
        </div>
        <div className={"kat-col-xs-12 kat-row"} id={"inputJsonRow"}>
          {showInputString()}
        </div>
      </div>
    </ResizableBox>
  );
};
