import React, { Component, ReactNode } from "react";
import axios from "axios";
import styles from "./SearchHome.module.css";
import { SearchBar } from "../SearchBar/SearchBar";
import { NavBar } from "../NavBar/NavBar";
import { ApiGatewayAdapter } from "../../lib/ApiGatewayAdapter";
import { getErrorMessage } from "../../utils/JSONUtils";
import { API_REGION_FOR_STAGE, BAC_URL, BARTON_WEBSITE_URL } from "../../common/AccountData";
import { marketplaceInfo } from "../../common/MarketplaceInfo";
import { Stages } from "../../common/StageInfo";
import { open, redirect } from "../../backend/dom";
import { TFunction, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { QueryAlerts } from "../QueryAlerts/QueryAlerts";
import { AlertIDs } from "../../common/Constants";
import { AlertDefinition, ApiResult, JSONObject, TableHistoryResult, TableResult, WorkflowResult } from "../../common/Types";
import { KatAlert } from "@amzn/katal-react";
import { WorkflowBox } from "../WorkflowBox/WorkflowBox";
import { DiffBox } from "../DiffBox/DiffBox";
import { sort as sortJson } from "json-keys-sort";
import { ResultAndToolsBox } from "../ResultAndToolsBox/ResultAndToolsBox";
import { CancelModal } from "../CancelModal/CancelModal";

type SearchHomeState = {
  stage: string;
  customerID: string;
  customerIDInputConstraint: string;
  realm: string;
  marketplace: string;
  marketplaceID: string;
  registrationFormDataResult: TableResult;
  registrationRequestResult: TableResult;
  abRegistrationResult: TableResult;
  workflowExecutionResult: WorkflowResult;
  showResults: boolean;
  loading: boolean;
  alertDefinitions: Array<AlertDefinition>;
  searchDisabled: boolean;
  loadingDiff: boolean;
  diffLoaded: boolean;
  registrationFormDataHistoryResult: TableHistoryResult;
  registrationRequestHistoryResult: TableHistoryResult;
  abRegistrationHistoryResult: TableHistoryResult;
  isCancellationModalShown: boolean;
  isCancellationLoading: boolean;
  isCancellationSuccess: boolean;
  isCancellationFail: boolean;
};

type SearchHomeProps = {
  customerID?: string;
  marketplace?: string;
  t: TFunction;
};

class SearchHome extends Component<SearchHomeProps & RouteComponentProps, SearchHomeState> {
  constructor(props: SearchHomeProps & RouteComponentProps) {
    super(props);
    this.state = {
      stage: "",
      customerID: "",
      customerIDInputConstraint: "",
      realm: "",
      marketplace: "",
      marketplaceID: "",
      registrationFormDataResult: {},
      registrationRequestResult: {},
      abRegistrationResult: {},
      workflowExecutionResult: {},
      showResults: false,
      loading: false,
      alertDefinitions: [],
      searchDisabled: false,
      loadingDiff: false,
      diffLoaded: false,
      registrationFormDataHistoryResult: {},
      registrationRequestHistoryResult: {},
      abRegistrationHistoryResult: {},
      isCancellationModalShown: false,
      isCancellationLoading: false,
      isCancellationSuccess: false,
      isCancellationFail: false,
    };
  }

  async componentDidMount() {
    const { stage } = await axios.get("/settings.json").then((response) => response.data);
    this.setState(() => {
      return { stage: stage };
    });
    ApiGatewayAdapter.initializeApiClient(stage, API_REGION_FOR_STAGE[stage]);
    if (this.props.match.url === "/search") {
      await this.initWithQueryParameters();
    }
    if (!this.state.marketplace) {
      this.setState(() => {
        return {
          realm: marketplaceInfo.US.realm,
          marketplace: marketplaceInfo.US.id,
          marketplaceID:
            this.state.stage === Stages.PROD.id ? marketplaceInfo.US.marketplaceIdForProd : marketplaceInfo.US.marketplaceIdForDevo,
        };
      });
    }
    if (!this.state.customerID) {
      this.setState(() => {
        return {
          searchDisabled: true,
          customerIDInputConstraint: this.props.t("customerid-input-constraint-label"),
        };
      });
    }
  }

  private async initWithQueryParameters() {
    const alertDefinitions = this.validateProps();
    const alertIds = alertDefinitions.map((alertDefinition) => alertDefinition.id);
    if (!alertIds.includes(AlertIDs.MISSING_CUSTOMER_ID)) {
      this.setState(() => {
        return {
          customerID: this.props.customerID,
        };
      });
    }
    if (!alertIds.includes(AlertIDs.WRONG_MARKETPLACE) && !alertIds.includes(AlertIDs.MISSING_MARKETPLACE)) {
      this.setState(() => {
        return {
          marketplace: this.props.marketplace,
          realm: marketplaceInfo[this.props.marketplace].realm,
          marketplaceID:
            this.state.stage === Stages.PROD.id
              ? marketplaceInfo[this.props.marketplace].marketplaceIdForProd
              : marketplaceInfo[this.props.marketplace].marketplaceIdForDevo,
        };
      });
    }
    if (alertDefinitions.length === 0) {
      this.setState(() => ({
        registrationFormDataResult: this.props.t("loading-registration-form-data"),
        registrationRequestResult: this.props.t("loading-registration-request"),
        abRegistrationResult: this.props.t("loading-ab-registration"),
        showResults: true,
        loading: true,
        loadingDiff: true,
        isCancellationSuccess: false,
        isCancellationFail: false,
      }));
      await this.fetchCustomerData();
    } else {
      this.setState(() => {
        return {
          alertDefinitions: alertDefinitions,
        };
      });
    }
  }

  private validateProps(): Array<AlertDefinition> {
    const alertDefinitions: Array<AlertDefinition> = [];
    if (!this.props.customerID) {
      alertDefinitions.push({
        id: AlertIDs.MISSING_CUSTOMER_ID,
        header: this.props.t("missing-customer-id-alert-header"),
        description: this.props.t("missing-customer-id-alert-message"),
      });
    }
    if (!this.props.marketplace) {
      alertDefinitions.push({
        id: AlertIDs.MISSING_MARKETPLACE,
        header: this.props.t("missing-marketplace-alert-header"),
        description: this.props.t("missing-marketplace-alert-message"),
      });
    } else if (!marketplaceInfo[this.props.marketplace]) {
      alertDefinitions.push({
        id: AlertIDs.WRONG_MARKETPLACE,
        header: this.props.t("wrong-marketplace-alert-header"),
        description: this.props.t("wrong-marketplace-alert-message", { marketplace: this.props.marketplace }),
      });
    }
    return alertDefinitions;
  }

  private async fetchCustomerData() {
    this.setState(() => ({
      loadingDiff: true,
      diffLoaded: false,
    }));
    const [
      registrationFormDataResult,
      registrationRequestResult,
      abRegistrationResult,
      workflowExecutionResult,
      registrationFormDataHistoryResult,
      registrationRequestHistoryResult,
      abRegistrationHistoryResult,
    ]: Array<ApiResult> = await Promise.allSettled([
      ApiGatewayAdapter.fetchRegistrationFormData(this.state.customerID, this.state.realm),
      ApiGatewayAdapter.fetchRegistrationRequest(this.state.customerID, this.state.stage, this.state.realm),
      ApiGatewayAdapter.fetchABRegistration(this.state.customerID, this.state.realm),
      ApiGatewayAdapter.fetchWorkflowsData(this.state.customerID, this.state.realm),
      ApiGatewayAdapter.fetchRegistrationFormDataHistory(this.state.customerID, this.state.realm),
      ApiGatewayAdapter.fetchRegistrationRequestHistory(this.state.customerID, this.state.stage, this.state.realm),
      ApiGatewayAdapter.fetchABRegistrationHistory(this.state.customerID, this.state.realm),
    ]).then((results) => results.map((result) => this.getResultInfo(result)));
    const [registrationFormDataResultSorted, registrationRequestResultSorted, abRegistrationResultSorted]: Array<TableResult> = [
      registrationFormDataResult,
      registrationRequestResult,
      abRegistrationResult,
    ].map((result: TableResult) => {
      return result.data ? { data: sortJson(result.data) as JSONObject } : { error: result.error };
    });
    const [
      registrationFormDataHistoryResultSorted,
      registrationRequestHistoryResultSorted,
      abRegistrationHistoryResultSorted,
    ]: Array<TableHistoryResult> = [registrationFormDataHistoryResult, registrationRequestHistoryResult, abRegistrationHistoryResult].map(
      (result: TableHistoryResult) => {
        return result.data
          ? { data: result.data.map((historyResult: JSONObject) => sortJson(historyResult) as JSONObject) }
          : { error: result.error };
      }
    );
    this.setState(() => ({
      registrationFormDataResult: registrationFormDataResultSorted,
      registrationRequestResult: registrationRequestResultSorted,
      abRegistrationResult: abRegistrationResultSorted,
      registrationFormDataHistoryResult: registrationFormDataHistoryResultSorted,
      registrationRequestHistoryResult: registrationRequestHistoryResultSorted,
      abRegistrationHistoryResult: abRegistrationHistoryResultSorted,
      workflowExecutionResult: workflowExecutionResult as WorkflowResult,
      loading: false,
      diffLoaded: true,
      loadingDiff: false,
    }));
  }

  private getResultInfo(result: PromiseSettledResult<any>): ApiResult {
    if (result.status === "fulfilled") {
      return {
        data: result.value,
      };
    } else {
      return {
        error: this.props.t("api-error-format", {
          status: result.reason.response.status,
          error: getErrorMessage(result.reason.response.data),
        }),
      };
    }
  }

  private async cancelBusinessRegistration() {
    await ApiGatewayAdapter.cancelBusinessRegistration(this.state.customerID, this.state.marketplaceID, this.state.realm);
  }

  onCancelBusinessRegistrationRequested = () => {
    this.setState({
      isCancellationModalShown: true,
    });
  };

  onCancelBusinessRegistrationConfirmed = async () => {
    this.setState({
      isCancellationModalShown: false,
      isCancellationLoading: true,
      isCancellationSuccess: false,
      isCancellationFail: false,
    });
    await this.cancelBusinessRegistration()
      .then(() => {
        this.setState({
          isCancellationLoading: false,
          isCancellationSuccess: true,
        });
      })
      .catch(() => {
        this.setState({
          isCancellationLoading: false,
          isCancellationFail: true,
        });
      });
  };

  onCancelBusinessRegistrationExited = () => {
    this.setState({
      isCancellationModalShown: false,
    });
  };

  customerIDChangedHandler = (customerID) => {
    if (!customerID) {
      this.setState(() => {
        return {
          customerID: customerID,
          customerIDInputConstraint: this.props.t("customerid-input-constraint-label"),
          searchDisabled: true,
        };
      });
    } else {
      this.setState(() => {
        return {
          customerID: customerID,
          customerIDInputConstraint: "",
          searchDisabled: false,
        };
      });
    }
  };

  marketplaceChangedHandler = (marketplace) => {
    this.setState(() => {
      return {
        realm: marketplaceInfo[marketplace].realm,
        marketplace: marketplaceInfo[marketplace].id,
        marketplaceID:
          this.state.stage !== Stages.PROD.id
            ? marketplaceInfo[marketplace].marketplaceIdForDevo
            : marketplaceInfo[marketplace].marketplaceIdForProd,
      };
    });
  };

  stageChangedHandler = (stage) => {
    redirect(`https://${stage}.console.registration.b2b.amazon.dev`);
  };

  searchButtonPressedHandler = async () => {
    const params = new URLSearchParams({ customerID: this.state.customerID, marketplace: this.state.marketplace });
    this.props.history.push({ pathname: "/search", search: params.toString() });
    this.setState(() => ({
      showResults: true,
      loading: true,
      loadingDiff: true,
      isCancellationFail: false,
      isCancellationSuccess: false,
    }));
    await this.fetchCustomerData();
  };

  accountExplainerRedirectClikedHandler = () => {
    const stage = this.state.stage === Stages.PROD.id ? "PROD" : "DEVO";
    open(`https://prime-subscriptions.business.a2z.com/CustomerId/${this.state.customerID}/${this.state.marketplace}/${stage}`);
  };

  corvegaRedirectClikedHandler = () => {
    open(
      `https://console-${this.state.realm.toLowerCase()}-benefits.integ.amazon.com/secure/console/customerlinks/search?customerId=${
        this.state.customerID
      }`
    );
  };

  bartonWebsiteRedirectClikedHandler = () => {
    open(BARTON_WEBSITE_URL[this.state.realm][this.state.stage] + `?customerId=${this.state.customerID}`);
  };

  bacRedirectClikedHandler = () => {
    open(BAC_URL[this.state.realm][this.state.stage] + `${this.state.customerID}`);
  };

  onAccordionExpanded = async () => {
    if (this.state.diffLoaded) {
      return;
    }
    await this.fetchHistoryData();
  };

  onLoadHistoryResultButtonPressed = async () => {
    await this.fetchHistoryData();
  };

  private async fetchHistoryData() {
    this.setState(() => ({
      loadingDiff: true,
      diffLoaded: false,
    }));
    const [
      registrationFormDataHistoryResult,
      registrationRequestHistoryResult,
      abRegistrationHistoryResult,
    ]: Array<ApiResult> = await Promise.allSettled([
      ApiGatewayAdapter.fetchRegistrationFormDataHistory(this.state.customerID, this.state.realm),
      ApiGatewayAdapter.fetchRegistrationRequestHistory(this.state.customerID, this.state.stage, this.state.realm),
      ApiGatewayAdapter.fetchABRegistrationHistory(this.state.customerID, this.state.realm),
    ]).then((results) => results.map((result) => this.getResultInfo(result)));
    const [
      registrationFormDataHistoryResultSorted,
      registrationRequestHistoryResultSorted,
      abRegistrationHistoryResultSorted,
    ]: Array<TableHistoryResult> = [registrationFormDataHistoryResult, registrationRequestHistoryResult, abRegistrationHistoryResult].map(
      (result: TableHistoryResult) => {
        return result.data
          ? { data: result.data.map((historyResult: JSONObject) => sortJson(historyResult) as JSONObject) }
          : { error: result.error };
      }
    );
    this.setState(() => {
      return {
        registrationFormDataHistoryResult: registrationFormDataHistoryResultSorted,
        registrationRequestHistoryResult: registrationRequestHistoryResultSorted,
        abRegistrationHistoryResult: abRegistrationHistoryResultSorted,
        diffLoaded: true,
        loadingDiff: false,
      };
    });
  }

  render(): ReactNode {
    return (
      <div>
        <QueryAlerts alertDefinitions={this.state.alertDefinitions} />
        <CancelModal
          isCancellationModalShown={this.state.isCancellationModalShown}
          customerId={this.state.customerID}
          marketplaceId={this.state.marketplaceID}
          onSubmit={this.onCancelBusinessRegistrationConfirmed}
          onExit={this.onCancelBusinessRegistrationExited}
        />
        <NavBar />
        <div className={styles.SearchMain}>
          {this.state.isCancellationSuccess && (
            <KatAlert
              className={styles.errorAlert}
              id="consoleCancelSuccess"
              variant="success"
              description={this.props.t("registration-cancelled-alert-success-message")}
              header={this.props.t("registration-cancelled-alert-header")}
            />
          )}
          {this.state.isCancellationFail && (
            <KatAlert
              className={styles.errorAlert}
              id="consoleCancelSuccess"
              variant="warning"
              description={this.props.t("registration-cancelled-alert-error-message")}
              header={this.props.t("registration-cancelled-alert-header")}
            />
          )}
          {this.state.stage == Stages.PROD.id && (
            <KatAlert
              className={styles.errorAlert}
              id={"consoleProdAlert"}
              variant={"danger"}
              persistent={true}
              description={this.props.t("prod-alert-message")}
              header={this.props.t("prod-alert-header")}
            />
          )}
          <SearchBar
            customerID={this.state.customerID}
            customerIDInputConstraint={this.state.customerIDInputConstraint}
            marketplace={this.state.marketplace}
            stage={this.state.stage}
            loading={this.state.loading}
            searchDisabled={this.state.searchDisabled}
            onCustomerIDChanged={this.customerIDChangedHandler}
            onSearchButtonPressed={this.searchButtonPressedHandler}
            onMarketplaceDropdownChanged={this.marketplaceChangedHandler}
            onStageChanged={this.stageChangedHandler}
          />
          <ResultAndToolsBox
            isLoading={this.state.loading}
            isLoadingDiff={this.state.loadingDiff}
            isShowResults={this.state.showResults}
            isLoadingCancellation={this.state.isCancellationLoading}
            isCancellationBeenExecuted={this.state.isCancellationSuccess || this.state.isCancellationFail}
            bartonWebsiteHandler={this.bartonWebsiteRedirectClikedHandler}
            bacHandler={this.bacRedirectClikedHandler}
            accountExplainerHandler={this.accountExplainerRedirectClikedHandler}
            corvegaHandler={this.corvegaRedirectClikedHandler}
            onCancelBusinessRegistrationPressed={this.onCancelBusinessRegistrationRequested}
            abRegistrationResult={this.state.abRegistrationResult}
            registrationFormDataResult={this.state.registrationFormDataResult}
            registrationRequestResult={this.state.registrationRequestResult}
            abRegistrationHistoryResult={this.state.abRegistrationHistoryResult}
          />
          <DiffBox
            loading={this.state.loading}
            loadingDiff={this.state.loadingDiff}
            diffLoaded={this.state.diffLoaded}
            registrationFormDataHistoryResult={this.state.registrationFormDataHistoryResult}
            registrationRequestHistoryResult={this.state.registrationRequestHistoryResult}
            abRegistrationHistoryResult={this.state.abRegistrationHistoryResult}
            registrationFormDataResult={this.state.registrationFormDataResult}
            registrationRequestResult={this.state.registrationRequestResult}
            abRegistrationResult={this.state.abRegistrationResult}
            onDiffFormAccordionExpanded={this.onAccordionExpanded}
            onLoadHistoryResultButtonPressed={this.onLoadHistoryResultButtonPressed}
            showResults={this.state.showResults}
          />
          <WorkflowBox
            loading={this.state.loading}
            showResults={this.state.showResults}
            customerID={this.state.customerID}
            workflowResult={this.state.workflowExecutionResult}
            realm={this.state.realm}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(SearchHome));
