import React, { ReactElement, useEffect, useState } from "react";
import {
  KatBox,
  KatButton,
  KatCheckbox,
  KatDatePicker,
  KatDropdown,
  KatIcon,
  KatPredictiveInput,
  KatRadiobuttonGroup,
  KatTooltip,
} from "@amzn/katal-react";
import styles from "./RedriveBar.module.css";
import { useTranslation } from "react-i18next";
import { Stages } from "../../common/StageInfo";
import { RedriveCheckboxesType, RedriveDatesType, RedriveTimesType } from "../../common/Constants";
import axios from "axios";

type RedriveBarProps = {
  stateMachineName: string;
  stateMachineNameInputConstraint: string;
  loading: boolean;
  redriveDisabled: boolean;
  redriveDisabledPolling: boolean;
  onStateMachineNameChanged: (stateMachineName: string) => void;
  stage: string;
  realm: string;
  onRealmDropdownChanged: (realm: string) => void;
  onStageChanged: (stage: string) => void;
  onRedriveButtonPressed: () => Promise<void>;
  override: boolean;
  resume: boolean;
  redriveFailed: boolean;
  redriveAborted: boolean;
  redriveTimedOut: boolean;
  redriveRunning: boolean;
  startDate: Date;
  startDateHours: number;
  startDateMinutes: number;
  endDate: Date;
  endDateHours: number;
  endDateMinutes: number;
  onDateChanged: (newDate: Date, dateType: RedriveDatesType) => void;
  onDateInvalid: (dateType: RedriveDatesType) => void;
  onTimeChanged: (newTime: string, timeType: RedriveTimesType, dateType: RedriveDatesType) => void;
  onCheckBoxChanged: (value: boolean, checkboxType: RedriveCheckboxesType) => void;
};

const getOptionsForHours = (): Array<any> => {
  const hours = [];
  for (let h = 0; h < 24; h++) {
    hours.push({
      name: `${h}`.padStart(2, "0"),
      value: `${h}`.padStart(2, "0"),
    });
  }
  return hours;
};

const getOptionsForMinutes = (): Array<any> => {
  const minutes = [];
  for (let m = 0; m < 60; m++) {
    minutes.push({
      name: `${m}`.padStart(2, "0"),
      value: `${m}`.padStart(2, "0"),
    });
  }
  return minutes;
};

const formatTimeNumber = (num: number): string => {
  return `${num}`.padStart(2, "0");
};

export const setStateMachineNamesEffect = (
  stage: string,
  setStateMachineNames: React.Dispatch<React.SetStateAction<Array<string>>>
): void => {
  useEffect(() => {
    async function fetchStateMachineNames() {
      const stateMachineNames = await axios.get("/redriveSettings.json").then((response) => response.data[stage].stateMachineNames);
      setStateMachineNames(stateMachineNames);
    }
    fetchStateMachineNames();
  }, []);
};

export const RedriveBar: React.FC<RedriveBarProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const [stateMachineNames, setStateMachineNames] = useState<Array<string>>([]);
  setStateMachineNamesEffect(props.stage, setStateMachineNames);

  const getRealmOptionsForStage = (): Array<any> => {
    return props.stage == Stages.PROD.id
      ? [
          {
            name: "NA (us-east-1)",
            value: "na",
          },
          {
            name: "EU (eu-west-1)",
            value: "eu",
          },
          {
            name: "FE (us-west-2)",
            value: "fe",
          },
        ]
      : [
          {
            name: "FE (us-west-2)",
            value: "fe",
          },
        ];
  };

  return (
    <div className={styles.redriveBar}>
      <KatBox variant="white">
        <h3>{t("redrive-bar-header")}</h3>
        <div className={"kat-row"}>
          <div className={"kat-col-xs-10 kat-row"}>
            <div className={`kat-col-xs-12 kat-row ${styles.firstRedriveRow}`}>
              <KatPredictiveInput
                id={"redriveStateMachineInput"}
                className={"kat-col-xs-6 kat-col-sm-4"}
                label={t("state-machine-input-label")}
                placeholder="StateMachineName"
                options={stateMachineNames}
                filterType={"startsWith"}
                value={props.stateMachineName}
                constraintEmphasis={props.stateMachineNameInputConstraint ? t("state-machine-input-constraint-emphasis") : ""}
                constraintLabel={props.stateMachineNameInputConstraint}
                onChange={(event: KatPredictiveInput.ChangeEvent) => props.onStateMachineNameChanged(event.detail.value)}
              />
              <KatDropdown
                id={"redriveRealmInput"}
                label={t("realm-dropdown-label")}
                className={"kat-col-xs-6 kat-col-sm-4"}
                options={getRealmOptionsForStage()}
                disabled={props.stage != Stages.PROD.id}
                value={props.realm}
                searchable={true}
                placeholder="Realm"
                onChange={(event) => {
                  props.onRealmDropdownChanged(event.detail.value);
                }}
              />
              <KatRadiobuttonGroup
                id={"redriveStageInput"}
                name={t("stage-input-label")}
                label={t("stage-input-label")}
                className={`${styles.horizontalGroup} kat-col-xs-12 kat-col-sm-4`}
                options={[
                  {
                    label: Stages.DEVO.displayName,
                    value: Stages.DEVO.id,
                  },
                  {
                    label: Stages.PROD.displayName,
                    value: Stages.PROD.id,
                  },
                ]}
                value={props.stage !== Stages.PROD.id ? Stages.DEVO.id : Stages.PROD.id}
                onChange={(event) => props.onStageChanged(event.detail.value)}
              />
            </div>
            <div className={`kat-row kat-col-xs-12 ${styles.secondRedriveRow}`}>
              <div className={"kat-col-xs-6 kat-col-sm-4 kat-row"}>
                <div className={"kat-col-xs-12"}>
                  <div>
                    {t("redrive-start-date-label")}
                    <KatTooltip
                      label={t("date-tooltip-message", { dateType: "Start Date" })}
                      triggerIcon={"help_outline"}
                      size={"tiny"}
                      position={"right"}
                    />
                  </div>
                  <KatDatePicker
                    id={"redriveStartDatePicker"}
                    className={styles.redriveDate}
                    onChange={(event: KatDatePicker.ChangeEvent) =>
                      props.onDateChanged(event.detail.dateObject, RedriveDatesType.startDate)
                    }
                    onInvalid={() => props.onDateInvalid(RedriveDatesType.startDate)}
                    value={props.startDate}
                  />
                </div>
                <KatDropdown
                  className={"kat-col-xs-6"}
                  id={"redriveStartDateHours"}
                  label={t("hours-dropdown-label")}
                  options={getOptionsForHours()}
                  searchable={true}
                  disabled={!props.startDate}
                  value={formatTimeNumber(props.startDateHours)}
                  onChange={(event: KatDropdown.ChangeEvent) =>
                    props.onTimeChanged(event.detail.value, RedriveTimesType.hours, RedriveDatesType.startDate)
                  }
                />
                <KatDropdown
                  className={"kat-col-xs-6"}
                  id={"redriveStartDateMinutes"}
                  label={t("minutes-dropdown-label")}
                  options={getOptionsForMinutes()}
                  searchable={true}
                  disabled={!props.startDate}
                  value={formatTimeNumber(props.startDateMinutes)}
                  onChange={(event: KatDropdown.ChangeEvent) =>
                    props.onTimeChanged(event.detail.value, RedriveTimesType.minutes, RedriveDatesType.startDate)
                  }
                />
              </div>
              <div className={"kat-col-xs-6 kat-col-sm-4 kat-row"}>
                <div className={"kat-col-xs-12"}>
                  <div>
                    {t("redrive-end-date-label")}
                    <KatTooltip
                      label={t("date-tooltip-message", { dateType: "End Date" })}
                      triggerIcon={"help_outline"}
                      size={"tiny"}
                      position={"right"}
                    />
                  </div>
                  <KatDatePicker
                    id={"redriveEndDatePicker"}
                    className={styles.redriveDate}
                    onChange={(event: KatDatePicker.ChangeEvent) => props.onDateChanged(event.detail.dateObject, RedriveDatesType.endDate)}
                    onInvalid={() => props.onDateInvalid(RedriveDatesType.endDate)}
                    value={props.endDate}
                  />
                </div>
                <KatDropdown
                  className={"kat-col-xs-6"}
                  id={"redriveEndDateHours"}
                  label={t("hours-dropdown-label")}
                  options={getOptionsForHours()}
                  searchable={true}
                  disabled={!props.endDate}
                  value={formatTimeNumber(props.endDateHours)}
                  onChange={(event: KatDropdown.ChangeEvent) =>
                    props.onTimeChanged(event.detail.value, RedriveTimesType.hours, RedriveDatesType.endDate)
                  }
                />
                <KatDropdown
                  className={"kat-col-xs-6"}
                  id={"redriveEndDateMinutes"}
                  label={t("minutes-dropdown-label")}
                  options={getOptionsForMinutes()}
                  searchable={true}
                  disabled={!props.endDate}
                  value={formatTimeNumber(props.endDateMinutes)}
                  onChange={(event: KatDropdown.ChangeEvent) =>
                    props.onTimeChanged(event.detail.value, RedriveTimesType.minutes, RedriveDatesType.endDate)
                  }
                />
              </div>
              <div className={`kat-col-xs-12 kat-col-sm-4 kat-row ${styles.flagCheckboxes}`}>
                <KatCheckbox
                  id={"redriveByDatesOverride"}
                  checked={props.override}
                  constraintLabel={t("workflow-redrive-override-constraint")}
                  label={t("workflow-redrive-override-label")}
                  className={"kat-col-xs-12"}
                  onChange={(event: KatCheckbox.ChangeEvent) =>
                    props.onCheckBoxChanged(event.detail.checked, RedriveCheckboxesType.override)
                  }
                />
                <KatCheckbox
                  id={"redriveByDatesResume"}
                  checked={props.resume}
                  constraintLabel={t("workflow-redrive-resume-constraint")}
                  label={t("workflow-redrive-resume-label")}
                  className={"kat-col-xs-12"}
                  onChange={(event: KatCheckbox.ChangeEvent) => props.onCheckBoxChanged(event.detail.checked, RedriveCheckboxesType.resume)}
                />
                <div className={`kat-col-xs-12 ${styles.failureTypeMessage}`}>{t("workflow-redrive-type-selector-label")}</div>
                <div className={"kat-col-xs-12 kat-row"}>
                  <KatCheckbox
                    id={"redriveByDatesRedriveFailed"}
                    checked={props.redriveFailed}
                    label={t("workflow-redrive-redriveFailed-label")}
                    className={"kat-col-xs-3 kat-col-sm-12 kat-col-md-6 kat-col-lg-3"}
                    onChange={(event: KatCheckbox.ChangeEvent) =>
                      props.onCheckBoxChanged(event.detail.checked, RedriveCheckboxesType.redriveFailed)
                    }
                  />
                  <KatCheckbox
                    id={"redriveByDatesRedriveAborted"}
                    checked={props.redriveAborted}
                    label={t("workflow-redrive-redriveAborted-label")}
                    className={"kat-col-xs-3 kat-col-sm-12 kat-col-md-6 kat-col-lg-3"}
                    onChange={(event: KatCheckbox.ChangeEvent) =>
                      props.onCheckBoxChanged(event.detail.checked, RedriveCheckboxesType.redriveAborted)
                    }
                  />
                  <KatCheckbox
                    id={"redriveByDatesRedriveTimedOut"}
                    checked={props.redriveTimedOut}
                    label={t("workflow-redrive-redriveTimedOut-label")}
                    className={"kat-col-xs-3 kat-col-sm-12 kat-col-md-6 kat-col-lg-3"}
                    onChange={(event: KatCheckbox.ChangeEvent) =>
                      props.onCheckBoxChanged(event.detail.checked, RedriveCheckboxesType.redriveTimedOut)
                    }
                  />
                  <KatCheckbox
                    id={"redriveByDatesRedriveRunning"}
                    checked={props.redriveRunning}
                    label={t("workflow-redrive-redriveRunning-label")}
                    className={"kat-col-xs-3 kat-col-sm-12 kat-col-md-6 kat-col-lg-3"}
                    onChange={(event: KatCheckbox.ChangeEvent) =>
                      props.onCheckBoxChanged(event.detail.checked, RedriveCheckboxesType.redriveRunning)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <KatButton
            id="redriveButton"
            onClick={props.onRedriveButtonPressed}
            className={`${styles.redriveButton} kat-col-xs-2`}
            variant="primary"
            loading={props.loading}
            disabled={props.redriveDisabled || props.redriveDisabledPolling}
          >
            <KatIcon
              id={"redriveButtonIcon"}
              name={props.redriveDisabled || props.redriveDisabledPolling ? "block" : "refresh"}
              size="small"
              slot="icon"
            />
            <div>{t("workflow-redrive-button")}</div>
          </KatButton>
        </div>
      </KatBox>
    </div>
  );
};
