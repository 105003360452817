// Inspired from https://code.amazon.com/packages/WorkEventsUIStaticWebsite/trees/mainline
// and https://code.amazon.com/packages/WorkEventsUICommonModule/trees/mainline

import React, { useState, ReactElement, Fragment, PropsWithChildren, useEffect } from "react";
import Auth from "@aws-amplify/auth";
import { AuthConfig } from "../../lib/AuthConfig";
import { getLocalStorage, getURL, redirect } from "../../backend/dom";
import { KatSpinner } from "@amzn/katal-react";

const AUTH_STATUS = {
  AUTHENTICATED: "AUTHENTICATED",
  UNAUTHENTICATED: "UNAUTHENTICATED",
};

export const AuthWrapper: React.FC = ({ children }: PropsWithChildren<any>): ReactElement => {
  const [authStatus, setAuthStatus] = useState(AUTH_STATUS.UNAUTHENTICATED);
  const [authInit, setAuthInit] = useState(false);
  const [, setTokenSaved] = useState(false);

  useEffect(() => {
    const getCurrentToken = async () => {
      if (!authInit) {
        await AuthConfig.init();
      }
      try {
        if (AuthConfig.validateIdToken()) {
          setAuthStatus(AUTH_STATUS.AUTHENTICATED);
          setAuthInit(true);
        } else {
          await AuthConfig.saveToken();
          setTokenSaved(true);
        }
      } catch (err) {
        console.error(err);
        setAuthStatus(AUTH_STATUS.UNAUTHENTICATED);
        setAuthInit(true);
      }
    };
    getCurrentToken();
  });

  useEffect(() => {
    if (authStatus === AUTH_STATUS.UNAUTHENTICATED && authInit) {
      const redirectURL = getURL();
      getLocalStorage().setItem("redirectURL", redirectURL);
      Auth.federatedSignIn({ customProvider: "AmazonFederate" });
    } else if (authStatus == AUTH_STATUS.AUTHENTICATED && authInit) {
      const redirectURL = getLocalStorage().getItem("redirectURL");
      if (redirectURL) {
        getLocalStorage().removeItem("redirectURL");
        redirect(redirectURL);
      }
    }
  }, [authStatus, authInit]);

  return (
    <Fragment>
      {authStatus === AUTH_STATUS.AUTHENTICATED ? children : <KatSpinner id="authSpinner" size="large" variant="default" />}
    </Fragment>
  );
};

export default AuthWrapper;
