import React, { ReactElement } from "react";
import { WorkflowError, WorkflowExecution } from "../common/Types";
import { KatBadge, KatIcon } from "@amzn/katal-react";
import styles from "./WorkflowUtilsStyles.module.css";
import { WorkflowStatusToBadgeTypeMap, WorkflowStatus } from "../common/Constants";

export type WorkflowInfo = {
  workflowID: string;
  parentID: string;
  redirectLink: string;
  redirectLinkParent: string;
  workflowOutput: JSON | WorkflowError;
  workflowOutputLabel: string;
};

export function getBadge(workflowExecution: WorkflowExecution): ReactElement {
  return (
    <div>
      {workflowExecution.parentArn && <KatBadge label={"REDRIVEN"} type={"info"} className={styles.redriveBadge} />}
      <KatBadge label={workflowExecution.status} type={WorkflowStatusToBadgeTypeMap[workflowExecution.status]} />
    </div>
  );
}

export function getLabel(workflowExecution: WorkflowExecution): ReactElement {
  const workflowName = workflowExecution.parentArn ? workflowExecution.workflowName.split("-")[0] : workflowExecution.workflowName;
  return (
    <div className={`kat-row ${styles.labelRow}`}>
      <div className={`kat-col-xs-12 kat-col-md-4 ${styles.workflowName}`}>{workflowName}</div>
      <div className={"kat-col-xs-6 kat-col-md-4"}>
        <KatIcon name="play-fill" size="small" />
        <span className={styles.headerDate}>{workflowExecution.startDate.toLocaleString()}</span>
      </div>
      {workflowExecution.status != WorkflowStatus.RUNNING ? (
        <div className={"kat-col-xs-6 kat-col-md-4"}>
          <KatIcon name="stop" size="small" />
          <span className={styles.headerDate}>{workflowExecution.stopDate.toLocaleString()}</span>
        </div>
      ) : (
        <div className={`kat-col-xs-6 kat-col-md-4 ${styles.hiddenStopDate}`}>
          <KatIcon name="stop" size="small" />
          <span className={styles.headerDate}>{"00/00/0000, 00:00:00"}</span>
        </div>
      )}
    </div>
  );
}

export function getWorkflowInfo(workflowExecution: WorkflowExecution): WorkflowInfo {
  const workflowID = `${workflowExecution.workflowName}:${workflowExecution.executionArn.split(":").pop()}`;
  const parentID =
    workflowExecution.parentArn && `${workflowExecution.parentArn.split(":")[6]}:${workflowExecution.parentArn.split(":")[7]}`;
  const redirectLink = getRedirectLinkFromArn(workflowExecution.executionArn);
  const redirectLinkParent = workflowExecution.parentArn ? getRedirectLinkFromArn(workflowExecution.parentArn) : null;
  const workflowOutput = workflowExecution.output ? JSON.parse(workflowExecution.output) : workflowExecution.workflowError;
  const workflowOutputLabel = workflowExecution.output ? "workflow-show-output" : "workflow-show-error";
  return {
    workflowID: workflowID,
    parentID: parentID,
    redirectLink: redirectLink,
    redirectLinkParent: redirectLinkParent,
    workflowOutput: workflowOutput,
    workflowOutputLabel: workflowOutputLabel,
  };
}

export function getRedirectLinkFromArn(executionArn: string): string {
  const splitArn = executionArn.split(":");
  const workflowRegion = splitArn[3];
  const accountId = splitArn[4];
  const baseLink = `https://${workflowRegion}.console.aws.amazon.com/states/home${encodeURIComponent(
    `?region=${workflowRegion}#`
  )}/executions/details`;
  const link = `${baseLink}/${executionArn}`;
  return getRedirectToLink(accountId, link);
}

export function getRedirectToLink(accountId: string, link: string): string {
  const redirectBase = `https://conduit.security.a2z.com/api/consoleUrl?awsAccountId=${accountId}&awsPartition=aws&redirect=true&policy=arn:aws:iam::aws:policy/ReadOnlyAccess&destination`;
  return `${redirectBase}=${link}`;
}
