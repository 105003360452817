import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styles from "./PartnershipBar.module.css";
import {
  KatBox,
  KatButton,
  KatDropdown,
  KatIcon,
  KatInput,
  KatLabel,
  KatList,
  KatPopover,
  KatRadiobuttonGroup,
  KatTextarea,
  KatTooltip,
} from "@amzn/katal-react";
import { Stages } from "../../common/StageInfo";
import { PartnershipType, PARTNERSHIP_REALMS } from "../../common/Constants";
import ReactJson, { InteractionProps } from "react-json-view";
import { PartnershipInputType, RealmValue } from "../../common/Types";

type PartnershipBarProps = {
  realm: RealmValue;
  stage: string;
  apiVersion: PartnershipType;
  partnershipInput: PartnershipInputType;
  onRealmDropdownChanged: (realm: string) => void;
  onStageChanged: (stage: string) => void;
  onApiVersionDropdownChanged: (apiVersion: PartnershipType) => void;
  loading: boolean;
  onCreateButtonPressed: () => Promise<void>;
  onFormChanged: (key: string, value: string) => void;
  onJsonChanged: (src: any) => void;
  onGenerateEmailButtonPressed: () => void;
  generatedEmail: string;
};

const getApiVersionOption = (): Array<any> => {
  return [
    {
      name: "Standard (3PB_1.0)",
      value: PartnershipType.standard,
    },
    {
      name: "Custom",
      value: PartnershipType.custom,
    },
  ];
};

export const PartnershipBar: React.FC<PartnershipBarProps> = (props): ReactElement => {
  const { t } = useTranslation();

  const getRealmOptionsForStage = (): Array<any> => {
    return props.stage == Stages.PROD.id
      ? [
          {
            name: PARTNERSHIP_REALMS.NA.name,
            value: PARTNERSHIP_REALMS.NA.id,
          },
          {
            name: PARTNERSHIP_REALMS.EU.name,
            value: PARTNERSHIP_REALMS.EU.id,
          },
          {
            name: PARTNERSHIP_REALMS.FE.name,
            value: PARTNERSHIP_REALMS.FE.id,
          },
        ]
      : [
          {
            name: PARTNERSHIP_REALMS.NA.name,
            value: PARTNERSHIP_REALMS.NA.id,
          },
        ];
  };

  const generateStandardApiVersionForm = () => {
    const formData = props.partnershipInput[PartnershipType[props.apiVersion]];
    return (
      <div className={"kat-row kat-col-xs-6"}>
        <div className={"kat-row kat-col-xs-12"}>
          <KatInput
            id={"partnershipBusinessNameInput"}
            className={"kat-col-xs-6"}
            label={t("partnership-business-name-input")}
            type="search"
            placeholder="businessName"
            value={formData.businessName}
            onInput={(event: any) => props.onFormChanged("businessName", event.target.value)}
          />
          <div className={"kat-col-xs-6"}>
            <div className={styles.labelWithTooltip}>
              {t("partnership-contact-email-input")}
              <KatTooltip label={t("partnership-contact-email-tooltip")} triggerIcon={"help_outline"} size={"tiny"} position={"right"} />
            </div>
            <KatInput
              id={"partnershipContactEmailInput"}
              type="search"
              placeholder="contactEmailId"
              value={formData.contactEmailId}
              onInput={(event: any) => props.onFormChanged("contactEmailId", event.target.value)}
            />
          </div>
        </div>
        <div className={"kat-row kat-col-xs-12"}>
          <KatInput
            id={"partnershipCampaignIdInput"}
            className={"kat-col-xs-6"}
            label={t("partnership-campaign-input")}
            type="search"
            placeholder="campaignId"
            value={formData.campaignId}
            onInput={(event: any) => props.onFormChanged("campaignId", event.target.value)}
          />
          <KatInput
            id={"partnershipContactNameInput"}
            className={"kat-col-xs-6"}
            label={t("partnership-contact-name-input")}
            type="search"
            placeholder="contactName"
            value={formData.contactName}
            onInput={(event: any) => props.onFormChanged("contactName", event.target.value)}
          />
        </div>
        <div className={"kat-row kat-col-xs-12"}>
          <KatInput
            id={"partnershipContactPhoneNumberInput"}
            className={"kat-col-xs-6"}
            label={t("partnership-contact-phone-number-input")}
            type="search"
            placeholder="contactPhoneNumber"
            value={formData.contactPhoneNumber}
            onInput={(event: any) => props.onFormChanged("contactPhoneNumber", event.target.value)}
          />
          <KatInput
            id={"partnershipBusinessAddressLine1Input"}
            className={"kat-col-xs-6"}
            label={t("partnership-business-address-line-1-input")}
            type="search"
            placeholder="businessAddressLine1"
            value={formData.businessAddressLine1}
            onInput={(event: any) => props.onFormChanged("businessAddressLine1", event.target.value)}
          />
        </div>
        <div className={"kat-row kat-col-xs-12"}>
          <KatInput
            id={"partnershipBusinessAddressLine2Input"}
            className={"kat-col-xs-6"}
            label={t("partnership-business-address-line-2-input")}
            type="search"
            placeholder="businessAddressLine2"
            value={formData.businessAddressLine2}
            onInput={(event: any) => props.onFormChanged("businessAddressLine2", event.target.value)}
          />
          <KatInput
            id={"partnershipBusinessCityInput"}
            className={"kat-col-xs-6"}
            label={t("partnership-business-city-input")}
            type="search"
            placeholder="businessCity"
            value={formData.businessCity}
            onInput={(event: any) => props.onFormChanged("businessCity", event.target.value)}
          />
        </div>
        <div className={"kat-row kat-col-xs-12"}>
          <KatInput
            id={"partnershipBusinessStateInput"}
            className={"kat-col-xs-6"}
            label={t("partnership-business-state-input")}
            type="search"
            placeholder="businessState"
            value={formData.businessState}
            onInput={(event: any) => props.onFormChanged("businessState", event.target.value)}
          />
          <KatInput
            id={"partnershipBusinessPostalCodeInput"}
            className={"kat-col-xs-6"}
            label={t("partnership-business-postal-code-input")}
            type="search"
            placeholder="businessPostalCode"
            value={formData.businessPostalCode}
            onInput={(event: any) => props.onFormChanged("businessPostalCode", event.target.value)}
          />
        </div>
        <div className={"kat-row kat-col-xs-12"}>
          <KatInput
            id={"partnershipBusinessCountryInput"}
            className={"kat-col-xs-6"}
            label={t("partnership-business-country-input")}
            type="search"
            placeholder="businessCountry"
            value={formData.businessCountry}
            onInput={(event: any) => props.onFormChanged("businessCountry", event.target.value)}
          />
          <KatInput
            id={"partnershipBusinessPhoneNumberInput"}
            className={"kat-col-xs-6"}
            label={t("partnership-business-phone-number-input")}
            type="search"
            placeholder="businessPhoneNumber"
            value={formData.businessPhoneNumber}
            onInput={(event: any) => props.onFormChanged("businessPhoneNumber", event.target.value)}
          />
        </div>
      </div>
    );
  };

  const generateFullForm = () => {
    return (
      <div className={`kat-row kat-col-xs-12 ${styles.inputRow}`}>
        {generateStandardApiVersionForm()}
        <div className={"kat-col-xs-6"}>{generateJsonForm()}</div>
      </div>
    );
  };

  const generateJsonForm = () => {
    return (
      <div>
        <KatPopover katAriaBehavior="tooltip" position="bottom" triggerType="hover">
          <button className="kat-no-style" slot="trigger">
            <KatLabel text={t("partnership-json-info")} />
            <KatIcon data-popover-anchor name="arrow_drop_down" size="small" />
          </button>
          <KatList variant="bullet">
            <li>{t("partnership-json-info-enter-edit")}</li>
            <li>{t("partnership-json-info-submit-edit")}</li>
            <li>{t("partnership-json-info-cancel-edit")}</li>
            <li>{t("partnership-json-info-enter-key")}</li>
            <li>{t("partnership-json-info-escape-key")}</li>
          </KatList>
        </KatPopover>
        <KatBox className={styles.jsonBox} variant={"white"}>
          <ReactJson
            src={props.partnershipInput[PartnershipType[props.apiVersion]]}
            displayDataTypes={false}
            enableClipboard={true}
            onEdit={(edit: InteractionProps) => props.onJsonChanged(edit.updated_src)}
            onAdd={(add: InteractionProps) => props.onJsonChanged(add.updated_src)}
            onDelete={(del: InteractionProps) => props.onJsonChanged(del.updated_src)}
          />
        </KatBox>
      </div>
    );
  };

  const generateCustomForm = () => {
    return (
      <div className={`kat-row ${styles.inputRow}`}>
        <div className={"kat-col-xs-12 kat-row"}>
          <KatPopover className={"kat-col-xs-6"} katAriaBehavior="tooltip" position="bottom" triggerType="hover">
            <button className="kat-no-style" slot="trigger">
              <KatLabel text={t("partnership-custom-info")} />
              <KatIcon data-popover-anchor name="arrow_drop_down" size="small" />
            </button>
            <KatList variant="bullet">
              <li>{t("partnership-custom-info-api-version")}</li>
              <li>{t("partnership-custom-info-partnerId")}</li>
              <li>{t("partnership-custom-info-contactEmailId")}</li>
              <li>{t("partnership-custom-info-generic")}</li>
            </KatList>
          </KatPopover>
        </div>
        <div className={"kat-col-xs-12 kat-row"}>
          <KatTextarea
            id={"partnershipCustomJsonInput"}
            value={JSON.stringify(props.partnershipInput[PartnershipType[props.apiVersion]], null, 2)}
            className={`kat-col-xs-6 ${styles.codeArea}`}
            onChange={(event: any) => props.onJsonChanged(event.target.value)}
          />
          <KatBox variant={"nordic"} className={"kat-col-xs-6"}>
            <h3 className={styles.generatedEmailHeader}>{t("partnership-generated-email")}</h3>
            <pre id="partnershipGeneratedEmail" className={styles.generatedEmailText}>
              {props.generatedEmail}
            </pre>
          </KatBox>
        </div>
      </div>
    );
  };

  const selectFormToGenerate = () => {
    if (props.apiVersion === PartnershipType.custom) {
      return generateCustomForm();
    } else {
      return generateFullForm();
    }
  };

  return (
    <div className={styles.partnershipBar}>
      <KatBox variant={"white"}>
        <h3>{t("partnership-bar-header")}</h3>
        <div className={"kat-row"}>
          <div className={"kat-col-xs-12 kat-row"}>
            <KatDropdown
              id={"partnershipApiVersionInput"}
              className={"kat-col-xs-4"}
              label={t("partnership-api-version-input-label")}
              options={getApiVersionOption()}
              value={props.apiVersion}
              searchable={true}
              placeholder={"API"}
              onChange={(event) => {
                props.onApiVersionDropdownChanged(PartnershipType[event.detail.value]);
              }}
            />
            <KatDropdown
              id={"partnershipRealmInput"}
              label={t("realm-dropdown-label")}
              className={"kat-col-xs-4"}
              options={getRealmOptionsForStage()}
              disabled={props.stage != Stages.PROD.id}
              value={props.realm.id}
              searchable={true}
              placeholder="Realm"
              onChange={(event) => {
                props.onRealmDropdownChanged(event.detail.value);
              }}
            />
            <KatRadiobuttonGroup
              id={"partnershipStageInput"}
              name={t("stage-input-label")}
              label={t("stage-input-label")}
              className={`${styles.horizontalGroup} kat-col-xs-4`}
              options={[
                {
                  label: Stages.DEVO.displayName,
                  value: Stages.DEVO.id,
                },
                {
                  label: Stages.PROD.displayName,
                  value: Stages.PROD.id,
                },
              ]}
              value={props.stage !== Stages.PROD.id ? Stages.DEVO.id : Stages.PROD.id}
              onChange={(event) => props.onStageChanged(event.detail.value)}
            />
          </div>
          {selectFormToGenerate()}
          <div className={"kat-col-xs-12 kat-row"}>
            <KatButton
              id="partnershipGenerateEmailButton"
              onClick={props.onGenerateEmailButtonPressed}
              className={`${styles.emailButton} kat-offset-xs-4 kat-col-xs-4`}
              variant="secondary"
            >
              <KatIcon id={"partnershipGenerateEmailButtonIcon"} name={"mail_outline"} size="small" slot="icon" />
              <div>{t("partnership-create-email")}</div>
            </KatButton>
          </div>
          <div className={"kat-col-xs-12 kat-row"}>
            <KatButton
              id="partnershipCreateButton"
              onClick={props.onCreateButtonPressed}
              className={`${styles.partnershipButton} kat-offset-xs-4 kat-col-xs-4`}
              variant="primary"
              loading={props.loading}
            >
              <KatIcon id={"partnershipButtonIcon"} name={"play"} size="small" slot="icon" />
              <div>{t("partnership-create-button")}</div>
            </KatButton>
          </div>
        </div>
      </KatBox>
    </div>
  );
};
