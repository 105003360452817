import React, { ReactElement, useEffect, useState } from "react";
import { KatBox, KatButton, KatCheckbox, KatDropdown, KatIcon, KatInput, KatPredictiveInput, KatRadiobuttonGroup } from "@amzn/katal-react";
import styles from "./KeyMasterBar.module.css";
import { useTranslation } from "react-i18next";
import { Stages } from "../../common/StageInfo";
import { KeyMasterAction, KeyMasterStringSide } from "../../common/Constants";
import axios from "axios";

type KeyMasterBarProps = {
  stage: string;
  realm: string;
  decodedString: string;
  encodedString: string;
  keyMasterDataType: string;
  actionToPerform: KeyMasterAction;
  usePrefix: boolean;
  loading: boolean;
  onStageChanged: (stage: string) => void;
  onRealmChanged: (realm: string) => void;
  onStringChanged: (newString: string, stringSide: KeyMasterStringSide) => void;
  onActionToPerformChanged: (previousAction: KeyMasterAction) => void;
  onKeyMasterDataTypeChanged: (newDataType: string) => void;
  onUsePrefixCheckboxChanged: (usePrefix: boolean) => void;
  onConvertButtonPressed: () => Promise<void>;
};

const getArrowDirection = (previousAction: KeyMasterAction): KatIcon.Name => {
  return previousAction === KeyMasterAction.ENCRYPT ? "arrow-right" : "arrow-left";
};

export const setKeyMasterDataTypesEffect = (setKeyMasterDataTypes: React.Dispatch<React.SetStateAction<Array<string>>>): void => {
  useEffect(() => {
    async function fetchDataTypes() {
      const dataTypes = await axios.get("/keyMasterSettings.json").then((response) => response.data.dataTypes);
      setKeyMasterDataTypes(dataTypes);
    }
    fetchDataTypes();
  }, []);
};

export const KeyMasterBar: React.FC<KeyMasterBarProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const [keyMasterDataTypes, setKeyMasterDataTypes] = useState<Array<string>>([]);
  setKeyMasterDataTypesEffect(setKeyMasterDataTypes);

  const getRealmOptionsForStage = (): Array<any> => {
    return props.stage == Stages.PROD.id
      ? [
          {
            name: "NA (us-east-1)",
            value: "na",
          },
          {
            name: "EU (eu-west-1)",
            value: "eu",
          },
          {
            name: "FE (us-west-2)",
            value: "fe",
          },
        ]
      : [
          {
            name: "FE (us-west-2)",
            value: "fe",
          },
        ];
  };

  return (
    <div className={styles.keyMasterBar}>
      <KatBox variant="white">
        <h3>{t("keymaster-bar-header")}</h3>
        <div className={"kat-row"}>
          <div className={`kat-row kat-col-xs-12 ${styles.firstRow}`}>
            <KatDropdown
              id={"keyMasterRealmInput"}
              label={t("realm-dropdown-label")}
              className={"kat-col-xs-3"}
              options={getRealmOptionsForStage()}
              disabled={props.stage != Stages.PROD.id}
              value={props.realm}
              searchable={true}
              placeholder="Realm"
              onChange={(event) => {
                props.onRealmChanged(event.detail.value);
              }}
            />
            <KatRadiobuttonGroup
              id={"keymasterStageInput"}
              name={t("stage-input-label")}
              label={t("stage-input-label")}
              className={`${styles.horizontalGroup} kat-col-xs-4 kat-col-sm-3 kat-col-md-2 kat-col-lg-2 kat-col-xl-1`}
              options={[
                {
                  label: Stages.DEVO.displayName,
                  value: Stages.DEVO.id,
                },
                {
                  label: Stages.PROD.displayName,
                  value: Stages.PROD.id,
                },
              ]}
              value={props.stage !== Stages.PROD.id ? Stages.DEVO.id : Stages.PROD.id}
              onChange={(event) => props.onStageChanged(event.detail.value)}
            />
            <KatCheckbox
              id={"keyMasterUsePrefix"}
              checked={props.usePrefix}
              constraintLabel={t("keymaster-usePrefix-constraint")}
              label={t("keymaster-usePrefix-label")}
              className={`kat-col-xs-5 kat-col-sm-6 ${styles.usePrefixCheckBox}`}
              onChange={(event: KatCheckbox.ChangeEvent) => props.onUsePrefixCheckboxChanged(event.detail.checked)}
            />
          </div>
          <div className={`kat-row kat-col-xs-12 ${styles.convertRow}`}>
            <KatInput
              id={"keyMasterDecodedInput"}
              className={"kat-col-xs-5"}
              label={t("keymaster-decoded-input-label")}
              placeholder="Decrypted Input"
              value={props.decodedString}
              onInput={(event: any) => props.onStringChanged(event.target.value, KeyMasterStringSide.DECODED_STRING)}
            />
            <KatButton
              id={"keyMasterSwitchSides"}
              variant={"secondary"}
              className={"kat-col-xs-2"}
              onClick={() => props.onActionToPerformChanged(props.actionToPerform)}
            >
              <KatIcon name={getArrowDirection(props.actionToPerform)} size="small" slot="icon" />
            </KatButton>
            <KatInput
              id={"keyMasterEncodedInput"}
              className={"kat-col-xs-5"}
              label={t("keymaster-encoded-input-label")}
              placeholder="Encrypted Input"
              value={props.encodedString}
              onInput={(event: any) => props.onStringChanged(event.target.value, KeyMasterStringSide.ENCODED_STRING)}
            />
          </div>
          {props.actionToPerform === KeyMasterAction.ENCRYPT && (
            <div className={`kat-col-xs-12 kat-row ${styles.dataTypeRow}`}>
              <KatPredictiveInput
                id={"keyMasterDataTypeInput"}
                className={"kat-offset-xs-4 kat-col-xs-4"}
                label={t("keymaster-data-type-input-label")}
                placeholder="KeyMaster DataType"
                options={keyMasterDataTypes}
                filterType={"contains"}
                value={props.keyMasterDataType}
                constraintEmphasis={!props.keyMasterDataType ? t("keymaster-data-type-input-constraint-emphasis") : ""}
                constraintLabel={!props.keyMasterDataType ? t("keymaster-data-type-input-constraint-label") : ""}
                onChange={(event: KatPredictiveInput.ChangeEvent) => props.onKeyMasterDataTypeChanged(event.detail.value)}
              />
            </div>
          )}
          <div className={"kat-col-xs-12 kat-row"}>
            <KatButton
              id="keyMasterConvertButton"
              onClick={props.onConvertButtonPressed}
              className={`${styles.convertButton} kat-offset-xs-4 kat-col-xs-4`}
              variant="primary"
              loading={props.loading}
              disabled={props.actionToPerform === KeyMasterAction.ENCRYPT && !props.keyMasterDataType}
            >
              <KatIcon id={"keyMasterConvertButtonIcon"} name={"swap_horiz"} size="small" slot="icon" />
              <div>{t("keymaster-convert-button")}</div>
            </KatButton>
          </div>
        </div>
      </KatBox>
    </div>
  );
};
