import React, { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AuthConfig } from "../../lib/AuthConfig";
import { NavBar } from "../NavBar/NavBar";
import styles from "./PartnershipHome.module.css";
import { Stages } from "../../common/StageInfo";
import { KatAlert } from "@amzn/katal-react";
import { TFunction, withTranslation } from "react-i18next";
import axios from "axios";
import { ApiGatewayAdapter } from "../../lib/ApiGatewayAdapter";
import { API_REGION_FOR_STAGE } from "../../common/AccountData";
import { PartnershipBar } from "../PartnershipBar/PartnershipBar";
import { doAlert, redirect } from "../../backend/dom";
import { DEFAULT_PARTNERSHIP_INPUT, PARTNERSHIP_REALMS, PartnershipType } from "../../common/Constants";
import { CreatePartnershipInvitationResult, PartnershipInputType, RealmValue } from "../../common/Types";
import { getErrorMessage } from "../../utils/JSONUtils";
import { PartnershipResultBox } from "../PartnershipResultBox/PartnershipResultBox";

type PartnershipHomeState = {
  stage: string;
  realm: RealmValue;
  apiVersion: PartnershipType;
  partnershipInput: PartnershipInputType;
  createInvitationInputString: string;
  createInvitationResult: CreatePartnershipInvitationResult;
  loading: boolean;
  showResult: boolean;
  generatedEmail: string;
};

type RedriveHomeProps = {
  t: TFunction;
};

class PartnershipHome extends Component<RedriveHomeProps & RouteComponentProps, PartnershipHomeState> {
  constructor(props: RedriveHomeProps & RouteComponentProps) {
    super(props);
    this.state = {
      stage: "",
      realm: PARTNERSHIP_REALMS.NA,
      apiVersion: PartnershipType.standard,
      partnershipInput: DEFAULT_PARTNERSHIP_INPUT,
      createInvitationInputString: "",
      createInvitationResult: {},
      loading: false,
      showResult: false,
      generatedEmail: "",
    };
  }

  async componentDidMount() {
    const { stage } = await axios.get("/settings.json").then((response) => response.data);
    this.setState(() => {
      return { stage: stage };
    });
    this.initEmailWithTimestamp();
    ApiGatewayAdapter.initializeApiClient(stage, API_REGION_FOR_STAGE[stage]);
  }

  private getRandomEmail = () => {
    const timestamp = Date.now();
    const alias = AuthConfig.getAlias();
    return `${alias}+${timestamp}@amazon.com`;
  };

  private initEmailWithTimestamp = () => {
    const defaultEmail = this.getRandomEmail();
    const partnershipInput = this.state.partnershipInput;
    for (const partnershipType in partnershipInput) {
      partnershipInput[PartnershipType[partnershipType]].contactEmailId = defaultEmail;
    }
    this.setState(() => {
      return { partnershipInput: partnershipInput, generatedEmail: defaultEmail };
    });
  };

  onRealmChanged = (realm: string) => {
    this.setState(() => {
      return {
        realm: PARTNERSHIP_REALMS[realm.toUpperCase()],
      };
    });
  };

  onStageChanged = (stage: string) => {
    redirect(`https://${stage}.console.registration.b2b.amazon.dev/partnership`);
  };

  onApiVersionChanged = (apiVersion: PartnershipType) => {
    this.setState(() => {
      return {
        apiVersion: apiVersion,
      };
    });
  };

  onCreateButtonPressed = async () => {
    const partnershipInput = this.state.partnershipInput;
    const partnershipJsonString = JSON.stringify(partnershipInput[PartnershipType[this.state.apiVersion]]);
    this.setState(() => {
      return {
        loading: true,
        showResult: true,
      };
    });
    const createInvitationResult: CreatePartnershipInvitationResult = await ApiGatewayAdapter.generatePartnershipInvitation(
      partnershipJsonString,
      this.state.realm.id
    ).then(
      (result) => {
        return {
          data: result,
        };
      },
      (reason) => {
        return {
          error: this.props.t("api-error-format", {
            status: reason.response.status,
            error: getErrorMessage(reason.response.data),
          }),
        };
      }
    );
    this.setState(() => {
      return {
        loading: false,
        createInvitationInputString: partnershipJsonString,
        createInvitationResult: createInvitationResult,
      };
    });
  };

  onFormChanged = (key: string, value: string) => {
    const partnershipInput = this.state.partnershipInput;
    partnershipInput[PartnershipType[this.state.apiVersion]][key] = value;
    this.setState(() => {
      return {
        partnershipInput: partnershipInput,
      };
    });
  };

  onJsonChanged = (src: any) => {
    if (typeof src === "string") {
      try {
        src = JSON.parse(src);
      } catch (e) {
        doAlert(this.props.t("partnership-json-parse-error", { error: e }));
        return;
      }
    }
    const apiVersion = src.apiVersion;
    let newApiVersion;
    switch (apiVersion) {
      case "3PB_1.0":
        newApiVersion = PartnershipType.standard;
        break;
      default:
        newApiVersion = PartnershipType.custom;
    }
    const partnershipInput = this.state.partnershipInput;
    partnershipInput[newApiVersion] = src;
    this.setState(() => {
      return {
        partnershipInput: partnershipInput,
        apiVersion: newApiVersion,
      };
    });
  };

  onGenerateEmailButtonPressed = () => {
    const randomEmail = this.getRandomEmail();
    const partnershipInput = this.state.partnershipInput;
    for (const partnershipType in partnershipInput) {
      if (partnershipInput[PartnershipType[partnershipType]].contactEmailId) {
        partnershipInput[PartnershipType[partnershipType]].contactEmailId = randomEmail;
      }
    }
    this.setState(() => {
      return { partnershipInput: partnershipInput, generatedEmail: randomEmail };
    });
  };

  render(): ReactNode {
    return AuthConfig.isAdmin() ? (
      <div>
        <NavBar />
        <div className={styles.partnershipMain}>
          {this.state.stage == Stages.PROD.id && (
            <KatAlert
              className={styles.errorAlert}
              id={"consolePartnershipProdAlert"}
              variant={"danger"}
              persistent={true}
              description={this.props.t("prod-alert-message")}
              header={this.props.t("prod-alert-header")}
            />
          )}
          <PartnershipBar
            realm={this.state.realm}
            stage={this.state.stage}
            apiVersion={this.state.apiVersion}
            partnershipInput={this.state.partnershipInput}
            onRealmDropdownChanged={this.onRealmChanged}
            onStageChanged={this.onStageChanged}
            onApiVersionDropdownChanged={this.onApiVersionChanged}
            loading={this.state.loading}
            onCreateButtonPressed={this.onCreateButtonPressed}
            onFormChanged={this.onFormChanged}
            onJsonChanged={this.onJsonChanged}
            onGenerateEmailButtonPressed={this.onGenerateEmailButtonPressed}
            generatedEmail={this.state.generatedEmail}
          />
          <PartnershipResultBox
            createInvitationInputString={this.state.createInvitationInputString}
            createInvitationResult={this.state.createInvitationResult}
            showResults={this.state.showResult}
            loading={this.state.loading}
          />
        </div>
      </div>
    ) : (
      <div>
        <NavBar />
        <div className={styles.partnershipMain}>
          <KatAlert
            className={styles.errorAlert}
            id={"consolePartnershipNoAdminAlert"}
            variant={"danger"}
            persistent={true}
            description={this.props.t("not-authorized-alert-message")}
            header={this.props.t("not-authorized-alert-header")}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(PartnershipHome));
