import "core-js/stable";
import "regenerator-runtime/runtime";
import {
  KEY_MASTER_INVOKE_API_ID,
  PARTNERSHIP_INVOKE_API_ID,
  RegionCodes,
  REGISTRATION_INVOKE_API_ID,
  TABLES_INVOKE_API_ID,
  WORKFLOWS_INVOKE_API_ID,
} from "../common/AccountData";
import { getLocalStorage } from "../backend/dom";
import { formatCloudWatchLogDates, formatWorkflowExecutionDates } from "../utils/JSONUtils";
import { WorkflowBatchRedrivePoll, WorkflowBatchRedriveStarted, WorkflowExecution } from "../common/Types";
import { KeyMasterAction } from "../common/Constants";

const apigClientFactory = require("aws-api-gateway-client").default;

interface ApiGatewayAdapterClients {
  [key: string]: any;
}

class ApiGatewayAdapterSingleton {
  private static tableClients: ApiGatewayAdapterClients = {};
  private static workflowClients: ApiGatewayAdapterClients = {};
  private static partnershipClients: ApiGatewayAdapterClients = {};
  private static keyMasterClient: ApiGatewayAdapterClients = {};
  private static abRegistrationClients: ApiGatewayAdapterClients = {};

  private static getApiClient(apiID: string, regionCode: string) {
    return apigClientFactory.newClient({
      invokeUrl: `https://${apiID}.execute-api.${regionCode}.amazonaws.com/prod`,
      headers: {
        authorization: getLocalStorage().getItem("idToken"),
      },
    });
  }

  initializeApiClient(stage: string, regions: RegionCodes): void {
    for (const region in regions) {
      ApiGatewayAdapterSingleton.tableClients[region] = ApiGatewayAdapterSingleton.getApiClient(
        TABLES_INVOKE_API_ID[stage][region],
        regions[region]
      );
      ApiGatewayAdapterSingleton.workflowClients[region] = ApiGatewayAdapterSingleton.getApiClient(
        WORKFLOWS_INVOKE_API_ID[stage][region],
        regions[region]
      );
      ApiGatewayAdapterSingleton.partnershipClients[region] = ApiGatewayAdapterSingleton.getApiClient(
        PARTNERSHIP_INVOKE_API_ID[stage][region],
        regions[region]
      );
      ApiGatewayAdapterSingleton.keyMasterClient[region] = ApiGatewayAdapterSingleton.getApiClient(
        KEY_MASTER_INVOKE_API_ID[stage][region],
        regions[region]
      );
      ApiGatewayAdapterSingleton.abRegistrationClients[region] = ApiGatewayAdapterSingleton.getApiClient(
        REGISTRATION_INVOKE_API_ID[stage][region],
        regions[region]
      );
    }
  }

  async fetchRegistrationFormData(customerID: string, realm: string): Promise<any> {
    const path = "/rfd";
    const additionalParams = {
      queryParams: {
        customerID: customerID,
      },
    };
    return ApiGatewayAdapterSingleton.fetchTableData(realm, path, additionalParams);
  }

  async fetchRegistrationFormDataHistory(customerID: string, realm: string): Promise<any> {
    const path = "/rfd-history";
    const additionalParams = {
      queryParams: {
        customerID: customerID,
      },
    };
    return ApiGatewayAdapterSingleton.fetchTableData(realm, path, additionalParams);
  }

  async fetchRegistrationRequest(customerID: string, stage: string, realm: string): Promise<any> {
    const path = "/registration-request";
    const additionalParams = {
      queryParams: {
        customerID: customerID,
      },
    };
    return ApiGatewayAdapterSingleton.fetchTableData(realm, path, additionalParams);
  }

  async fetchRegistrationRequestHistory(customerID: string, stage: string, realm: string): Promise<any> {
    const path = "/registration-request-history";
    const additionalParams = {
      queryParams: {
        customerID: customerID,
      },
    };
    return ApiGatewayAdapterSingleton.fetchTableData(realm, path, additionalParams);
  }

  async fetchABRegistration(customerID: string, realm: string): Promise<any> {
    const path = "/ab-registration";
    const additionalParams = {
      queryParams: {
        customerID: customerID,
      },
    };
    return ApiGatewayAdapterSingleton.fetchTableData(realm, path, additionalParams);
  }

  async fetchABRegistrationHistory(customerID: string, realm: string): Promise<any> {
    const path = "/ab-registration-history";
    const additionalParams = {
      queryParams: {
        customerID: customerID,
      },
    };
    return ApiGatewayAdapterSingleton.fetchTableData(realm, path, additionalParams);
  }

  private static async fetchTableData(realm: string, path: string, additionalParams: any): Promise<any> {
    const params = {};
    const method = "GET";
    const body = {};

    const apiResult = await ApiGatewayAdapterSingleton.tableClients[realm.toLowerCase()].invokeApi(
      params,
      path,
      method,
      additionalParams,
      body
    );
    return apiResult.data;
  }

  async fetchWorkflowsData(customerID: string, realm: string): Promise<any> {
    const params = {};
    const path = "/get-workflows";
    const method = "GET";
    const additionalParams = {
      queryParams: {
        customerID: customerID,
      },
    };
    const body = {};

    const apiResult = await ApiGatewayAdapterSingleton.workflowClients[realm.toLowerCase()].invokeApi(
      params,
      path,
      method,
      additionalParams,
      body
    );
    return formatWorkflowExecutionDates(apiResult.data);
  }

  async redriveWorkflow(executionArn: string, override: boolean, resuming: boolean, realm: string): Promise<WorkflowExecution> {
    const params = {};
    const path = "/redrive-workflow";
    const method = "GET";
    const additionalParams = {
      queryParams: {
        executionArn: executionArn,
        override: override,
        resuming: resuming,
      },
    };
    const body = {};

    const apiResult = await ApiGatewayAdapterSingleton.workflowClients[realm.toLowerCase()].invokeApi(
      params,
      path,
      method,
      additionalParams,
      body
    );
    return formatWorkflowExecutionDates([apiResult.data]).pop();
  }

  async redriveWorkflowsByDates(
    stateMachineArn: string,
    override: boolean,
    resuming: boolean,
    redriveFailed: boolean,
    redriveAborted: boolean,
    redriveTimedOut: boolean,
    redriveRunning: boolean,
    startDate: Date,
    endDate: Date,
    realm: string
  ): Promise<WorkflowBatchRedriveStarted> {
    const params = {};
    const path = "/redrive-workflows-by-dates";
    const method = "GET";
    const additionalParams = {
      queryParams: {
        stateMachineArn: stateMachineArn,
        override: override,
        resuming: resuming,
        redriveFailed: redriveFailed,
        redriveAborted: redriveAborted,
        redriveTimedOut: redriveTimedOut,
        redriveRunning: redriveRunning,
      },
    };
    if (startDate) {
      additionalParams.queryParams["startDate"] = startDate.toISOString();
    }
    if (endDate) {
      additionalParams.queryParams["endDate"] = endDate.toISOString();
    }
    const body = {};

    const apiResult = await ApiGatewayAdapterSingleton.workflowClients[realm.toLowerCase()].invokeApi(
      params,
      path,
      method,
      additionalParams,
      body
    );
    const redriveByDatesStartResult = apiResult.data;
    return {
      executionArn: redriveByDatesStartResult.executionArn,
      logGroupName: redriveByDatesStartResult.logGroupName,
      logStreamName: redriveByDatesStartResult.logStreamName,
    };
  }

  async pollRedriveWorkflowsByDates(
    executionArn: string,
    logGroupName: string,
    logStreamName: string,
    realm: string
  ): Promise<WorkflowBatchRedrivePoll> {
    const params = {};
    const path = "/redrive-workflows-by-dates-poller";
    const method = "GET";
    const additionalParams = {
      queryParams: {
        executionArn: executionArn,
        logGroupName: logGroupName,
        logStreamName: logStreamName,
      },
    };
    const body = {};

    const apiResult = await ApiGatewayAdapterSingleton.workflowClients[realm.toLowerCase()].invokeApi(
      params,
      path,
      method,
      additionalParams,
      body
    );
    const pollResult = apiResult.data;
    const redriveByDatesResult = apiResult.data.redriveByDatesResult
      ? {
          attemptedRedrives: pollResult.redriveByDatesResult.attemptedRedrives,
          successfullyLaunched: pollResult.redriveByDatesResult.successfullyLaunched,
          failedToLaunch: pollResult.redriveByDatesResult.failedToLaunch,
          newExecutionDescriptions: formatWorkflowExecutionDates(pollResult.redriveByDatesResult.newExecutionDescriptions),
          redriveErrors: pollResult.redriveByDatesResult.redriveErrors,
        }
      : null;
    return {
      redriveByDatesResult: redriveByDatesResult,
      logEvents: formatCloudWatchLogDates(pollResult.logEvents),
      workflowError: pollResult.workflowError,
    };
  }

  async generatePartnershipInvitation(partnershipJsonString: string, realm: string): Promise<any> {
    const params = {};
    const path = "/create-invitation";
    const method = "POST";
    const additionalParams = {};

    const apiResult = await ApiGatewayAdapterSingleton.partnershipClients[realm.toLowerCase()].invokeApi(
      params,
      path,
      method,
      additionalParams,
      partnershipJsonString
    );
    return apiResult.data;
  }

  async convertWithKeyMaster(
    stringToConvert: string,
    keyMasterDataType: string,
    usePrefix: boolean,
    actionToPerform: KeyMasterAction,
    realm: string
  ): Promise<any> {
    const params = {};
    const path = "/keymaster-convert";
    const method = "GET";
    const additionalParams = {
      queryParams: {
        stringToConvert: stringToConvert,
        keyMasterDataType: keyMasterDataType,
        usePrefix: usePrefix,
        actionToPerform: actionToPerform,
      },
    };
    const body = {};

    const apiResult = await ApiGatewayAdapterSingleton.keyMasterClient[realm.toLowerCase()].invokeApi(
      params,
      path,
      method,
      additionalParams,
      body
    );
    return apiResult.data;
  }

  async cancelBusinessRegistration(customerId: string, marketplaceId: string, realm: string) {
    const params = {};
    const path = "/business-registration";
    const method = "DELETE";
    const additionalParams = {
      queryParams: {
        customerId: customerId,
        marketplaceId: marketplaceId,
      },
    };
    const body = {};

    const apiResult = await ApiGatewayAdapterSingleton.abRegistrationClients[realm.toLowerCase()].invokeApi(
      params,
      path,
      method,
      additionalParams,
      body
    );
    return apiResult.data;
  }
}

export const ApiGatewayAdapter = new ApiGatewayAdapterSingleton();
