class Stage {
  public id: string;
  public displayName: string;

  constructor(id, displayName) {
    this.id = id;
    this.displayName = displayName;
  }
}

const BETA = new Stage("beta", "Devo");
const PROD = new Stage("prod", "Prod");

export const Stages = {
  DEVO: BETA,
  PROD: PROD,
};
