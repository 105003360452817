interface AuthPoolConfig {
  [key: string]: string;
}
interface StageAuthPoolConfig {
  [key: string]: AuthPoolConfig;
}

export const AUTH_CONFIG: StageAuthPoolConfig = {
  beta: {
    userPoolId: "us-west-2_biaxU7hd9",
    clientId: "3ndepqs394ihe5keil166gd8n6",
    domain: "abregistration-console-beta.auth.us-west-2.amazoncognito.com",
  },
  prod: {
    userPoolId: "us-west-2_wCMD2xOQO",
    clientId: "7fa7q0lnijc1ifcggiutvv7kv3",
    domain: "abregistration-console-prod.auth.us-west-2.amazoncognito.com",
  },
};

export interface RegionCodes {
  [key: string]: string;
}
interface StageRegion {
  [key: string]: RegionCodes;
}
interface StageApiId {
  [key: string]: RegionCodes;
}

export const ACCOUNT_ID_FOR_STAGE = {
  beta: "634133347187",
  prod: "237126355621",
};

export const API_REGION_FOR_STAGE: StageRegion = {
  beta: {
    na: "us-west-2",
    eu: "us-west-2",
    fe: "us-west-2",
  },
  prod: {
    na: "us-east-1",
    eu: "eu-west-1",
    fe: "us-west-2",
  },
};

export const TABLES_INVOKE_API_ID: StageApiId = {
  beta: {
    na: "17w52cd3tc",
    eu: "17w52cd3tc",
    fe: "17w52cd3tc",
  },
  prod: {
    na: "59ja10a74l",
    eu: "r7toy2prf0",
    fe: "415a9wvt9c",
  },
};

export const WORKFLOWS_INVOKE_API_ID: StageApiId = {
  beta: {
    na: "2dpqeir471",
    eu: "2dpqeir471",
    fe: "2dpqeir471",
  },
  prod: {
    na: "rgkkwbgqqj",
    eu: "5ql3vi8xe1",
    fe: "ilv8hqkk50",
  },
};

export const PARTNERSHIP_INVOKE_API_ID: StageApiId = {
  beta: {
    na: "nmznw0h0if",
    eu: "nmznw0h0if",
    fe: "nmznw0h0if",
  },
  prod: {
    na: "w12cdfcmib",
    eu: "9ejatm6sac",
    fe: "2tg6yg3fhe",
  },
};

export const KEY_MASTER_INVOKE_API_ID: StageApiId = {
  beta: {
    na: "burze3s3qe",
    eu: "burze3s3qe",
    fe: "burze3s3qe",
  },
  prod: {
    na: "sehiz2yimf",
    eu: "hfpg91vh5a",
    fe: "urim0olob1",
  },
};

export const REGISTRATION_INVOKE_API_ID: StageApiId = {
  beta: {
    na: "6w895orn3c",
    eu: "6w895orn3c",
    fe: "6w895orn3c",
  },
  prod: {
    na: "oxlllzb6f3",
    eu: "bzk7xebn63",
    fe: "bc7uly12d3",
  },
};

export const BARTON_WEBSITE_URL = {
  NA: {
    beta: "https://b2b-verify-console.integ.amazon.com/secure/dev-console/customer/debug",
    prod: "https://b2b-verify-console-iad.amazon.com/secure/dev-console/customer/debug",
  },
  EU: {
    beta: "https://b2b-verify-console-eu.integ.amazon.com/secure/dev-console/customer/debug",
    prod: "https://b2b-verify-console-dub.dub.proxy.amazon.com/secure/dev-console/customer/debug",
  },
  FE: {
    beta: "https://b2b-verify-console-fe.integ.amazon.com/secure/dev-console/customer/debug",
    prod: "https://b2b-verify-console-fe-pdx.pdx.proxy.amazon.com/secure/dev-console/customer/debug",
  },
};

export const BAC_URL = {
  NA: {
    beta: "https://b2b-console.integ.amazon.com/secure/console2/customer/",
    prod: "https://b2b-console.amazon.com/secure/console/epd/customer/",
  },
  EU: {
    beta: "https://b2b-console-eu.integ.amazon.com/secure/console2/customer/",
    prod: "https://b2b-console-dub.aka.amazon.com/secure/console/epd/customer/",
  },
  FE: {
    beta: "https://b2b-console-fe.integ.amazon.com/secure/console2/customer/",
    prod: "https://b2b-console-pdx.aka.amazon.com/secure/console/epd/customer/",
  },
};
