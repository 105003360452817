import React, { ReactElement } from "react";
import { KatAlert } from "@amzn/katal-react";
import styles from "../QueryAlerts/QueryAlerts.module.css";
import { AlertDefinition } from "../../common/Types";

type QueryAlertsProps = {
  alertDefinitions: Array<AlertDefinition>;
};

export const QueryAlerts: React.FC<QueryAlertsProps> = (props): ReactElement => {
  return (
    <div>
      {props.alertDefinitions.map((alertDef) => {
        return (
          <KatAlert
            key={alertDef.id}
            id={alertDef.id}
            className={styles.QueryAlert}
            description={alertDef.description}
            header={alertDef.header}
            variant="warning"
          />
        );
      })}
    </div>
  );
};
