// Inspired from https://code.amazon.com/packages/WorkEventsUIStaticWebsite/trees/mainline
// and https://code.amazon.com/packages/WorkEventsUICommonModule/trees/mainline

import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { AUTH_CONFIG } from "../common/AccountData";
import { getLocalStorage, getOrigin } from "../backend/dom";

class AuthConfigSingleton {
  async init(): Promise<void> {
    const { stage } = await axios.get("/settings.json").then((response) => response.data);
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: "us-west-2",
        userPoolId: AUTH_CONFIG[stage].userPoolId,
        userPoolWebClientId: AUTH_CONFIG[stage].clientId,
      },
      oauth: {
        domain: AUTH_CONFIG[stage].domain,
        redirectSignIn: getOrigin(),
        redirectSignOut: getOrigin(),
        scope: ["openid"],
        responseType: "code",
      },
    });
  }

  parseToken(): any {
    const idToken = getLocalStorage().getItem("parsedToken");
    if (idToken) {
      return JSON.parse(idToken);
    } else {
      throw new Error("Missing valid Token!");
    }
  }

  validateIdToken(): boolean {
    try {
      const jsonToken = this.parseToken();
      if (Date.now() < jsonToken.exp * 1000 - 50) {
        return true;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
    return false;
  }

  async saveToken(): Promise<void> {
    await Auth.currentAuthenticatedUser();
    const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    getLocalStorage().setItem("idToken", idToken);
    getLocalStorage().setItem("parsedToken", JSON.stringify(jwt_decode(idToken)));
  }

  isAdmin(): boolean {
    try {
      const jsonToken = this.parseToken();
      return jsonToken["custom:POSIX_GROUP"] === "ADMIN";
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  getAlias(): string {
    try {
      const jsonToken = this.parseToken();
      return jsonToken["nickname"];
    } catch (e) {
      console.error(e);
      return "missingAlias";
    }
  }
}

export const AuthConfig = new AuthConfigSingleton();
