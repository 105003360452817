import React, { Component, ReactNode } from "react";
import styles from "./RedriveModal.module.css";
import { KatAccordion, KatAccordionItem, KatBadge, KatButton, KatCheckbox, KatDivider, KatLink, KatModal } from "@amzn/katal-react";
import { TFunction, withTranslation } from "react-i18next";
import { ApiGatewayAdapter } from "../../lib/ApiGatewayAdapter";
import { ResizableBox } from "../ResizableBox/ResizableBox";
import { WorkflowExecution } from "../../common/Types";
import { getErrorMessage } from "../../utils/JSONUtils";
import ReactJson from "react-json-view";
import { AuthConfig } from "../../lib/AuthConfig";
import { WorkflowStatusToBadgeTypeMap, WorkflowStatus } from "../../common/Constants";
import { getWorkflowInfo, WorkflowInfo } from "../../utils/WorkflowUtils";

type RedriveError = {
  status: number;
  message: string;
};

type RedriveResult = {
  data?: WorkflowExecution;
  error?: RedriveError;
};

type RedriveModalProps = {
  visible: boolean;
  executionARN: string;
  updateVisible: React.Dispatch<React.SetStateAction<boolean>>;
  realm: string;
  addRedrivenExecution: (redrivenWorkflow: WorkflowExecution, parentExecutionArn: string) => void;
  t: TFunction;
};

type RedriveModalState = {
  override: boolean;
  resume: boolean;
  loading: boolean;
  showRedriveResult: boolean;
  redriveResult: RedriveResult;
};

class RedriveModal extends Component<RedriveModalProps, RedriveModalState> {
  constructor(props) {
    super(props);
    this.state = {
      override: true,
      resume: true,
      loading: false,
      showRedriveResult: false,
      redriveResult: {},
    };
  }

  private onClose = (event: KatModal.CloseEvent): void => {
    this.props.updateVisible(event.detail.visible);
    this.setState(() => {
      return {
        showRedriveResult: false,
        override: true,
        resume: true,
      };
    });
  };

  private onCancel = (): void => {
    this.props.updateVisible(false);
    this.setState(() => {
      return {
        showRedriveResult: false,
        override: true,
        resume: true,
      };
    });
  };

  private onRedrive = async (): Promise<void> => {
    this.setState(() => {
      return {
        loading: true,
        showRedriveResult: true,
      };
    });
    const redriveResult = await ApiGatewayAdapter.redriveWorkflow(
      this.props.executionARN,
      this.state.override,
      this.state.resume,
      this.props.realm
    ).then(
      (result) => {
        this.props.addRedrivenExecution(result, this.props.executionARN);
        return {
          data: result,
        };
      },
      (reason) => {
        return {
          error: {
            status: reason.response.status,
            message: getErrorMessage(reason.response.data),
          },
        };
      }
    );
    this.setState(() => {
      return {
        loading: false,
        redriveResult: redriveResult,
      };
    });
  };

  private showRedrivenResult = (workflowExecution: WorkflowExecution) => {
    const workflowInfo: WorkflowInfo = getWorkflowInfo(workflowExecution);
    return (
      <div>
        <p>{this.props.t("workflow-redrive-success")}</p>
        <KatDivider variant="eastern" />
        <p>
          <b>{this.props.t("workflow-redrive-state-machine")}</b>: {workflowExecution.workflowName}
        </p>
        <p>
          <b>{this.props.t("workflow-redrive-arn-success")}</b>: {workflowExecution.executionArn}
        </p>
        <p>
          <b>{this.props.t("workflow-redrive-status")}</b>:
          <KatBadge label={workflowExecution.status} type={WorkflowStatusToBadgeTypeMap[workflowExecution.status]} />
        </p>
        <p>
          <b>{this.props.t("workflow-start-date")}</b>: {workflowExecution.startDate.toUTCString()}
        </p>
        {workflowExecution.status != WorkflowStatus.RUNNING && (
          <p>
            <b>{this.props.t("workflow-stop-date")}</b>: {workflowExecution.stopDate.toUTCString()}
          </p>
        )}
        <KatAccordion>
          <KatAccordionItem label={this.props.t("workflow-show-input")} remainsExpanded={true}>
            <ReactJson src={JSON.parse(workflowExecution.input)} enableClipboard={false} displayDataTypes={false} />
          </KatAccordionItem>
        </KatAccordion>
        {workflowExecution.status != WorkflowStatus.RUNNING && (
          <KatAccordion>
            <KatAccordionItem label={this.props.t(workflowInfo.workflowOutputLabel)} remainsExpanded={true}>
              <ReactJson src={workflowInfo.workflowOutput} enableClipboard={false} displayDataTypes={false} />
            </KatAccordionItem>
          </KatAccordion>
        )}
        <p>
          {AuthConfig.isAdmin() && (
            <KatLink
              href={workflowInfo.redirectLink}
              label={this.props.t("workflow-redirect-aws")}
              variant="link"
              target="_blank"
              className={styles.consoleLink}
            />
          )}
        </p>
        <p>
          {AuthConfig.isAdmin() && (
            <KatLink
              href={workflowInfo.redirectLinkParent}
              label={this.props.t("workflow-redirect-parent")}
              variant="link"
              target="_blank"
            />
          )}
        </p>
      </div>
    );
  };

  private showErrorMessage = () => {
    return (
      <div>
        <p>{this.props.t("workflow-redrive-error")}</p>
        <KatDivider variant="eastern" />
        <p>
          <b>{this.props.t("workflow-redrive-error-status")}</b>: {this.state.redriveResult.error.status}
        </p>
        <p>
          <b>{this.props.t("workflow-redrive-error-message")}</b>: {this.state.redriveResult.error.message}
        </p>
      </div>
    );
  };
  private selectMessageToShow = () => {
    if (this.state.loading) {
      return this.props.t("workflow-redrive-loading");
    }
    if (this.state.redriveResult.error) {
      return this.showErrorMessage();
    }
    if (this.state.redriveResult.data) {
      return this.showRedrivenResult(this.state.redriveResult.data);
    }
    return null;
  };

  render(): ReactNode {
    const workflowName = this.props.executionARN && this.props.executionARN.split(":")[6];
    return (
      <KatModal visible={this.props.visible} onClose={this.onClose}>
        <span slot="title">{this.props.t("workflow-redrive-title", { workflowName: workflowName })}</span>
        <p className={styles.modalText}>
          {this.props.t("workflow-redrive-header-text", { workflowName: workflowName })} <b>{workflowName}</b> <br />
          {this.props.t("workflow-redrive-arn-text", { executionARN: this.props.executionARN })}
        </p>
        <KatCheckbox
          checked={this.state.override}
          constraintLabel={this.props.t("workflow-redrive-override-constraint")}
          label={this.props.t("workflow-redrive-override-label")}
          onChange={(event: KatCheckbox.ChangeEvent) =>
            this.setState(() => {
              return {
                override: event.detail.checked,
              };
            })
          }
        />
        <KatCheckbox
          checked={this.state.resume}
          constraintLabel={this.props.t("workflow-redrive-resume-constraint")}
          label={this.props.t("workflow-redrive-resume-label")}
          onChange={(event: KatCheckbox.ChangeEvent) =>
            this.setState(() => {
              return {
                resume: event.detail.checked,
              };
            })
          }
        />
        <div className={styles.redriveResultBox}>
          <ResizableBox showResults={this.state.showRedriveResult} variant={"zircon"}>
            {this.selectMessageToShow()}
          </ResizableBox>
        </div>
        <div className="kat-group-horizontal" slot="footer">
          <KatButton label={this.props.t("workflow-redrive-button-cancel")} variant="secondary" onClick={this.onCancel} />
          <KatButton label={this.props.t("workflow-redrive-button")} onClick={this.onRedrive} loading={this.state.loading} />
        </div>
      </KatModal>
    );
  }
}

export default withTranslation()(RedriveModal);
