import React, { Component, ReactNode } from "react";
import axios from "axios";
import { NavBar } from "../NavBar/NavBar";
import { ApiGatewayAdapter } from "../../lib/ApiGatewayAdapter";
import { ACCOUNT_ID_FOR_STAGE, API_REGION_FOR_STAGE } from "../../common/AccountData";
import { TFunction, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  AlertDefinition,
  WorkflowBatchRedrivePoll,
  WorkflowBatchRedrivePollResult,
  WorkflowBatchRedriveStartedResult,
} from "../../common/Types";
import { Stages } from "../../common/StageInfo";
import { KatAlert } from "@amzn/katal-react";
import styles from "./RedriveHome.module.css";
import { RedriveBar } from "../RedriveBar/RedriveBar";
import { redirect } from "../../backend/dom";
import { AlertIDs, RedriveCheckboxesType, RedriveDatesType, RedriveTimesType } from "../../common/Constants";
import { QueryAlerts } from "../QueryAlerts/QueryAlerts";
import { AuthConfig } from "../../lib/AuthConfig";
import { getErrorMessage } from "../../utils/JSONUtils";
import { RedriveResultBox } from "../RedriveResultBox/RedriveResultBox";
import { CancelToken, poll } from "../../utils/PollUtils";

type RedriveHomeState = {
  stage: string;
  realm: string;
  redriveHappenedRealm: string;
  stateMachineName: string;
  stateMachineInputConstraint: string;
  redriveDisabled: boolean;
  redriveDisabledPolling: boolean;
  startDate: Date;
  startDateHours: number;
  startDateMinutes: number;
  endDate: Date;
  endDateHours: number;
  endDateMinutes: number;
  showResults: boolean;
  loading: boolean;
  startLoading: boolean;
  alertDefinitions: Array<AlertDefinition>;
  override: boolean;
  resume: boolean;
  redriveFailed: boolean;
  redriveAborted: boolean;
  redriveTimedOut: boolean;
  redriveRunning: boolean;
  redrivePollResult: WorkflowBatchRedrivePollResult;
  redriveStartedResult: WorkflowBatchRedriveStartedResult;
  polling: boolean;
  pollingStopping: boolean;
  pollingStopped: boolean;
  pollCancelToken: CancelToken;
};

type RedriveHomeProps = {
  realm?: string;
  stateMachineName?: string;
  override?: boolean;
  resume?: boolean;
  startDate?: string;
  endDate?: string;
  redriveFailed?: boolean;
  redriveAborted?: boolean;
  redriveTimedOut?: boolean;
  redriveRunning?: boolean;
  t: TFunction;
};

class RedriveHome extends Component<RedriveHomeProps & RouteComponentProps, RedriveHomeState> {
  constructor(props: RedriveHomeProps & RouteComponentProps) {
    super(props);
    this.state = {
      stage: "",
      realm: "",
      redriveHappenedRealm: "",
      stateMachineName: "",
      stateMachineInputConstraint: "",
      redriveDisabled: false,
      redriveDisabledPolling: false,
      startDate: null,
      startDateHours: 0,
      startDateMinutes: 0,
      endDate: null,
      endDateHours: 0,
      endDateMinutes: 0,
      showResults: false,
      loading: false,
      startLoading: false,
      alertDefinitions: [],
      override: true,
      resume: true,
      redriveFailed: true,
      redriveAborted: true,
      redriveTimedOut: true,
      redriveRunning: false,
      redrivePollResult: {},
      redriveStartedResult: {},
      polling: false,
      pollingStopping: false,
      pollingStopped: true,
      pollCancelToken: new CancelToken(),
    };
  }

  async componentDidMount() {
    const { stage } = await axios.get("/settings.json").then((response) => response.data);
    const realm = stage == Stages.PROD.id ? "na" : "fe";
    this.setState(() => {
      return { stage: stage, realm: realm };
    });
    ApiGatewayAdapter.initializeApiClient(stage, API_REGION_FOR_STAGE[stage]);
    if (this.props.location.search.length != 0) {
      await this.initWithQueryParameters();
    }
    if (!this.state.stateMachineName) {
      this.setState(() => {
        return {
          redriveDisabled: true,
          stateMachineInputConstraint: this.props.t("state-machine-input-constraint-label"),
        };
      });
    }
  }

  private async initWithQueryParameters() {
    const alertDefinitions = this.validateProps();
    const alertIds = alertDefinitions.map((alertDefinition) => alertDefinition.id);
    if (!alertIds.includes(AlertIDs.MISSING_STATE_MACHINE_NAME_REDRIVE)) {
      this.setState(() => {
        return {
          stateMachineName: this.props.stateMachineName,
        };
      });
    }
    if (!alertIds.includes(AlertIDs.MISSING_REALM_REDRIVE) && !alertIds.includes(AlertIDs.WRONG_REALM_REDRIVE)) {
      this.setState(() => {
        return {
          realm: this.props.realm,
        };
      });
    }
    if (!alertIds.includes(AlertIDs.MISSING_WRONG_OVERRIDE_REDRIVE)) {
      this.setState(() => {
        return {
          override: this.props.override,
        };
      });
    }
    if (!alertIds.includes(AlertIDs.MISSING_WRONG_RESUME_REDRIVE)) {
      this.setState(() => {
        return {
          resume: this.props.resume,
        };
      });
    }
    if (!alertIds.includes(AlertIDs.MISSING_WRONG_REDRIVE_FAILED_BOOL)) {
      this.setState(() => {
        return {
          redriveFailed: this.props.redriveFailed,
        };
      });
    }
    if (!alertIds.includes(AlertIDs.MISSING_WRONG_REDRIVE_ABORTED_BOOL)) {
      this.setState(() => {
        return {
          redriveAborted: this.props.redriveAborted,
        };
      });
    }
    if (!alertIds.includes(AlertIDs.MISSING_WRONG_REDRIVE_TIMED_OUT_BOOL)) {
      this.setState(() => {
        return {
          redriveTimedOut: this.props.redriveTimedOut,
        };
      });
    }
    if (!alertIds.includes(AlertIDs.MISSING_WRONG_REDRIVE_RUNNING_BOOL)) {
      this.setState(() => {
        return {
          redriveRunning: this.props.redriveRunning,
        };
      });
    }
    if (this.props.startDate && !alertIds.includes(AlertIDs.WRONG_START_DATE_REDRIVE)) {
      const startDate = new Date(this.props.startDate);
      this.setState(() => {
        return {
          startDate: startDate,
          startDateHours: startDate.getUTCHours(),
          startDateMinutes: startDate.getUTCMinutes(),
        };
      });
    }
    if (this.props.endDate && !alertIds.includes(AlertIDs.WRONG_END_DATE_REDRIVE)) {
      const endDate = new Date(this.props.endDate);
      this.setState(() => {
        return {
          endDate: endDate,
          endDateHours: endDate.getUTCHours(),
          endDateMinutes: endDate.getUTCMinutes(),
        };
      });
    }
    this.setState(() => {
      return {
        alertDefinitions: alertDefinitions,
      };
    });
  }

  private validateProps(): Array<AlertDefinition> {
    const allowedRealms: Array<string> = this.state.stage == Stages.PROD.id ? ["na", "eu", "fe"] : ["fe"];
    const alertDefinitions: Array<AlertDefinition> = [];
    if (!this.props.stateMachineName) {
      alertDefinitions.push({
        id: AlertIDs.MISSING_STATE_MACHINE_NAME_REDRIVE,
        header: this.props.t("missing-state-machine-alert-header"),
        description: this.props.t("missing-state-machine-alert-message"),
      });
    }
    if (!this.props.realm) {
      alertDefinitions.push({
        id: AlertIDs.MISSING_REALM_REDRIVE,
        header: this.props.t("missing-realm-alert-header"),
        description: this.props.t("missing-realm-alert-message"),
      });
    } else if (!allowedRealms.includes(this.props.realm)) {
      alertDefinitions.push({
        id: AlertIDs.WRONG_REALM_REDRIVE,
        header: this.props.t("wrong-realm-alert-header"),
        description: this.props.t("wrong-realm-alert-message", { realm: this.props.realm }),
      });
    }
    if (this.props.override === null) {
      alertDefinitions.push({
        id: AlertIDs.MISSING_WRONG_OVERRIDE_REDRIVE,
        header: this.props.t("missing-wrong-override-alert-header"),
        description: this.props.t("missing-wrong-override-alert-message"),
      });
    }
    if (this.props.resume === null) {
      alertDefinitions.push({
        id: AlertIDs.MISSING_WRONG_RESUME_REDRIVE,
        header: this.props.t("missing-wrong-resume-alert-header"),
        description: this.props.t("missing-wrong-resume-alert-message"),
      });
    }
    if (this.props.redriveFailed === null) {
      alertDefinitions.push({
        id: AlertIDs.MISSING_WRONG_REDRIVE_FAILED_BOOL,
        header: this.props.t("missing-wrong-redrive-failed-bool-alert-header"),
        description: this.props.t("missing-wrong-redrive-failed-bool-alert-message"),
      });
    }
    if (this.props.redriveAborted === null) {
      alertDefinitions.push({
        id: AlertIDs.MISSING_WRONG_REDRIVE_ABORTED_BOOL,
        header: this.props.t("missing-wrong-redrive-aborted-bool-alert-header"),
        description: this.props.t("missing-wrong-redrive-aborted-bool-alert-message"),
      });
    }
    if (this.props.redriveTimedOut === null) {
      alertDefinitions.push({
        id: AlertIDs.MISSING_WRONG_REDRIVE_TIMED_OUT_BOOL,
        header: this.props.t("missing-wrong-redrive-timed-out-bool-alert-header"),
        description: this.props.t("missing-wrong-redrive-timed-out-bool-alert-message"),
      });
    }
    if (this.props.redriveRunning === null) {
      alertDefinitions.push({
        id: AlertIDs.MISSING_WRONG_REDRIVE_RUNNING_BOOL,
        header: this.props.t("missing-wrong-redrive-running-bool-alert-header"),
        description: this.props.t("missing-wrong-redrive-running-bool-alert-message"),
      });
    }
    if (this.props.startDate && !this.isIsoUTCDate(this.props.startDate)) {
      alertDefinitions.push({
        id: AlertIDs.WRONG_START_DATE_REDRIVE,
        header: this.props.t("wrong-start-date-alert-header"),
        description: this.props.t("wrong-start-date-alert-message", { startDate: this.props.startDate }),
      });
    }
    if (this.props.endDate && !this.isIsoUTCDate(this.props.endDate)) {
      alertDefinitions.push({
        id: AlertIDs.WRONG_END_DATE_REDRIVE,
        header: this.props.t("wrong-end-date-alert-header"),
        description: this.props.t("wrong-end-date-alert-message", { endDate: this.props.endDate }),
      });
    }
    return alertDefinitions;
  }

  private isIsoUTCDate(date: string): boolean {
    return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date);
  }

  onStateMachineChanged = (stateMachineName) => {
    if (!stateMachineName) {
      this.setState(() => {
        return {
          stateMachineName: stateMachineName,
          stateMachineInputConstraint: this.props.t("state-machine-input-constraint-label"),
          redriveDisabled: true,
        };
      });
    } else {
      this.setState(() => {
        return {
          stateMachineName: stateMachineName,
          stateMachineInputConstraint: "",
          redriveDisabled: false,
        };
      });
    }
  };

  onRealmChanged = (realm) => {
    this.setState(() => {
      return {
        realm: realm,
      };
    });
  };

  onStageChanged = (stage) => {
    redirect(`https://${stage}.console.registration.b2b.amazon.dev/redrive`);
  };

  onRedriveButtonPressed = async () => {
    const params = new URLSearchParams({
      realm: this.state.realm,
      stateMachineName: this.state.stateMachineName,
      override: `${this.state.override}`,
      resume: `${this.state.resume}`,
      redriveFailed: `${this.state.redriveFailed}`,
      redriveAborted: `${this.state.redriveAborted}`,
      redriveTimedOut: `${this.state.redriveTimedOut}`,
      redriveRunning: `${this.state.redriveRunning}`,
    });
    if (this.state.startDate) {
      params.set("startDate", this.state.startDate.toISOString());
    }
    if (this.state.endDate) {
      params.set("endDate", this.state.endDate.toISOString());
    }
    this.props.history.push({ pathname: "/redrive", search: params.toString() });
    this.setState(() => {
      return {
        showResults: true,
        loading: true,
        startLoading: true,
        redriveStartedResult: {},
        redrivePollResult: {},
        redriveHappenedRealm: API_REGION_FOR_STAGE[this.state.stage][this.state.realm],
      };
    });
    const stateMachineArn = `arn:aws:states:${API_REGION_FOR_STAGE[this.state.stage][this.state.realm]}:${
      ACCOUNT_ID_FOR_STAGE[this.state.stage]
    }:stateMachine:${this.state.stateMachineName}`;
    const redriveStartedResult: WorkflowBatchRedriveStartedResult = await ApiGatewayAdapter.redriveWorkflowsByDates(
      stateMachineArn,
      this.state.override,
      this.state.resume,
      this.state.redriveFailed,
      this.state.redriveAborted,
      this.state.redriveTimedOut,
      this.state.redriveRunning,
      this.state.startDate,
      this.state.endDate,
      this.state.realm
    ).then(
      (result) => {
        return {
          data: result,
        };
      },
      (reason) => {
        return {
          error: this.props.t("api-error-format", {
            status: reason.response.status,
            error: getErrorMessage(reason.response.data),
          }),
        };
      }
    );
    this.setState(() => {
      return {
        redriveStartedResult: redriveStartedResult,
        startLoading: false,
      };
    });
    if (redriveStartedResult.error) {
      this.setState(() => {
        return {
          loading: false,
        };
      });
      return;
    }
    await this.pollForResult(
      redriveStartedResult.data.executionArn,
      redriveStartedResult.data.logGroupName,
      redriveStartedResult.data.logStreamName
    );
  };

  onCancelPollingPressed = () => {
    this.state.pollCancelToken.cancel();
    this.setState(() => {
      return {
        pollingStopping: true,
      };
    });
  };

  onStartPollingPressed = async (executionArn: string, logGroupName: string, logStreamName: string) => {
    this.setState(() => {
      return {
        redriveDisabledPolling: true,
      };
    });
    await this.pollForResult(executionArn, logGroupName, logStreamName);
  };

  private async pollForResult(executionArn: string, logGroupName: string, logStreamName: string): Promise<void> {
    this.setState(() => {
      return {
        polling: true,
        pollingStopping: false,
        pollingStopped: false,
      };
    });
    this.state.pollCancelToken.reset();
    const redrivePollResult = await poll(
      ApiGatewayAdapter.pollRedriveWorkflowsByDates,
      [executionArn, logGroupName, logStreamName, this.state.realm],
      (result: WorkflowBatchRedrivePoll) => !result.redriveByDatesResult && !result.workflowError,
      {
        waitCallbackBeforeWait: () => {
          this.setState(() => {
            return {
              polling: false,
            };
          });
        },
        waitCallbackAfterWait: () => {
          this.setState(() => {
            return {
              polling: true,
            };
          });
        },
        waitCallBackForResult: (result: WorkflowBatchRedrivePoll) => {
          this.setState(() => {
            return {
              redrivePollResult: { data: result },
            };
          });
        },
        cancelToken: this.state.pollCancelToken,
      }
    ).then(
      (result) => {
        return {
          data: result,
        };
      },
      (reason) => {
        return {
          error: this.props.t("api-error-format", {
            status: reason.response.status,
            error: getErrorMessage(reason.response.data),
          }),
        };
      }
    );
    this.setState(() => {
      return {
        redrivePollResult: redrivePollResult,
        polling: false,
        pollingStopping: false,
        pollingStopped: true,
        loading: false,
        redriveDisabledPolling: false,
      };
    });
  }

  onDateChanged = (newDate: Date, dateType: RedriveDatesType) => {
    const hours = dateType === RedriveDatesType.startDate ? this.state.startDateHours : this.state.endDateHours;
    const minutes = dateType === RedriveDatesType.startDate ? this.state.startDateMinutes : this.state.endDateMinutes;
    const newDateWithTime =
      newDate !== null ? new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), hours, minutes)) : null;
    switch (dateType) {
      case RedriveDatesType.startDate:
        this.setState(() => {
          return {
            startDate: newDateWithTime,
          };
        });
        break;
      case RedriveDatesType.endDate:
        this.setState(() => {
          return {
            endDate: newDateWithTime,
          };
        });
    }
  };

  onDateInvalid = (dateType: RedriveDatesType) => {
    switch (dateType) {
      case RedriveDatesType.startDate:
        this.setState(() => {
          return {
            startDate: null,
          };
        });
        break;
      case RedriveDatesType.endDate:
        this.setState(() => {
          return {
            endDate: null,
          };
        });
    }
  };

  onTimeChanged = (newTime: string, timeType: RedriveTimesType, dateType: RedriveDatesType) => {
    const newTimeNumber = parseInt(newTime);
    switch (timeType | dateType) {
      case RedriveTimesType.hours | RedriveDatesType.startDate:
        {
          this.state.startDate.setUTCHours(newTimeNumber);
          this.setState(() => {
            return {
              startDateHours: newTimeNumber,
            };
          });
        }
        break;
      case RedriveTimesType.hours | RedriveDatesType.endDate:
        {
          this.state.endDate.setUTCHours(newTimeNumber);
          this.setState(() => {
            return {
              endDateHours: newTimeNumber,
            };
          });
        }
        break;
      case RedriveTimesType.minutes | RedriveDatesType.startDate:
        {
          this.state.startDate.setUTCMinutes(newTimeNumber);
          this.setState(() => {
            return {
              startDateMinutes: newTimeNumber,
            };
          });
        }
        break;
      case RedriveTimesType.minutes | RedriveDatesType.endDate:
        {
          this.state.endDate.setUTCMinutes(newTimeNumber);
          this.setState(() => {
            return {
              endDateMinutes: newTimeNumber,
            };
          });
        }
        break;
    }
  };

  onCheckBoxChanged = (value: boolean, checkboxType: RedriveCheckboxesType) => {
    switch (checkboxType) {
      case RedriveCheckboxesType.override:
        this.setState(() => {
          return {
            override: value,
          };
        });
        break;
      case RedriveCheckboxesType.resume:
        this.setState(() => {
          return {
            resume: value,
          };
        });
        break;
      case RedriveCheckboxesType.redriveFailed:
        this.setState(() => {
          return {
            redriveFailed: value,
          };
        });
        break;
      case RedriveCheckboxesType.redriveAborted:
        this.setState(() => {
          return {
            redriveAborted: value,
          };
        });
        break;
      case RedriveCheckboxesType.redriveTimedOut:
        this.setState(() => {
          return {
            redriveTimedOut: value,
          };
        });
        break;
      case RedriveCheckboxesType.redriveRunning:
        this.setState(() => {
          return {
            redriveRunning: value,
          };
        });
        break;
    }
  };

  render(): ReactNode {
    return AuthConfig.isAdmin() ? (
      <div>
        <QueryAlerts alertDefinitions={this.state.alertDefinitions} />
        <NavBar />
        <div className={styles.redriveMain}>
          {this.state.stage == Stages.PROD.id && (
            <KatAlert
              className={styles.errorAlert}
              id={"consoleRedriveProdAlert"}
              variant={"danger"}
              persistent={true}
              description={this.props.t("prod-alert-message")}
              header={this.props.t("prod-alert-header")}
            />
          )}
          {this.state.stage && (
            <RedriveBar
              stateMachineName={this.state.stateMachineName}
              stateMachineNameInputConstraint={this.state.stateMachineInputConstraint}
              loading={this.state.loading}
              redriveDisabled={this.state.redriveDisabled}
              redriveDisabledPolling={this.state.redriveDisabledPolling}
              onStateMachineNameChanged={this.onStateMachineChanged}
              stage={this.state.stage}
              realm={this.state.realm}
              onRealmDropdownChanged={this.onRealmChanged}
              onStageChanged={this.onStageChanged}
              onRedriveButtonPressed={this.onRedriveButtonPressed}
              override={this.state.override}
              resume={this.state.resume}
              redriveFailed={this.state.redriveFailed}
              redriveAborted={this.state.redriveAborted}
              redriveTimedOut={this.state.redriveTimedOut}
              redriveRunning={this.state.redriveRunning}
              startDate={this.state.startDate}
              startDateHours={this.state.startDateHours}
              startDateMinutes={this.state.startDateMinutes}
              endDate={this.state.endDate}
              endDateHours={this.state.endDateHours}
              endDateMinutes={this.state.endDateMinutes}
              onDateChanged={this.onDateChanged}
              onDateInvalid={this.onDateInvalid}
              onTimeChanged={this.onTimeChanged}
              onCheckBoxChanged={this.onCheckBoxChanged}
            />
          )}
          <RedriveResultBox
            stage={this.state.stage}
            redriveHappenedRealm={this.state.redriveHappenedRealm}
            startLoading={this.state.startLoading}
            polling={this.state.polling}
            pollingStopping={this.state.pollingStopping}
            pollingStopped={this.state.pollingStopped}
            showResults={this.state.showResults}
            redrivePollResult={this.state.redrivePollResult}
            redriveStartedResult={this.state.redriveStartedResult}
            onCancelPollingPressed={this.onCancelPollingPressed}
            onStartPollingPressed={this.onStartPollingPressed}
          />
        </div>
      </div>
    ) : (
      <div>
        <NavBar />
        <div className={styles.redriveMain}>
          <KatAlert
            className={styles.errorAlert}
            id={"consoleRedriveNoAdminAlert"}
            variant={"danger"}
            persistent={true}
            description={this.props.t("not-authorized-alert-message")}
            header={this.props.t("not-authorized-alert-header")}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(RedriveHome));
