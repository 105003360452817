import { ResizableBox } from "../ResizableBox/ResizableBox";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./ToolsStatusBox.module.css";
import { KatBadge } from "@amzn/katal-react";
import { REGISTRATION_STATUS } from "../../common/Constants";

interface ToolsStatusBoxProps {
  isShowResults: boolean;
  status: string;
}

type BadgeType = "default" | "info" | "success" | "warning";

interface StatusParsedValues {
  parsedPhrase: string;
  badgeType: BadgeType;
}

const statusToParsedValues = new Map<string, StatusParsedValues>([
  [REGISTRATION_STATUS.UNKNOWN, { parsedPhrase: "status-unknown-text", badgeType: "default" }],
  [REGISTRATION_STATUS.REGISTRATION_FAILED, { parsedPhrase: "status-failed-text", badgeType: "warning" }],
  [REGISTRATION_STATUS.REGISTRATION_REJECTED, { parsedPhrase: "status-rejected-text", badgeType: "warning" }],
  [REGISTRATION_STATUS.REGISTRATION_PENDING, { parsedPhrase: "status-pending-text", badgeType: "info" }],
  [REGISTRATION_STATUS.REGISTERED, { parsedPhrase: "status-registered-text", badgeType: "success" }],
  [REGISTRATION_STATUS.CANCELLED, { parsedPhrase: "status-cancelled-text", badgeType: "warning" }],
]);

export const ToolsStatusBox: React.FC<ToolsStatusBoxProps> = (props) => {
  const { t } = useTranslation();

  const registrationStatusBadgeId = "registrationStatusBadgeId";

  return (
    <ResizableBox showResults={props.isShowResults}>
      <h3>{t("status-header")}</h3>
      <KatBadge
        className={styles.badge}
        id={registrationStatusBadgeId}
        variant="default"
        type={statusToParsedValues.get(props.status).badgeType}
        label="registrationStatus"
      >
        {t(statusToParsedValues.get(props.status).parsedPhrase)}
      </KatBadge>
    </ResizableBox>
  );
};
