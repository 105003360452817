import React, { ReactElement } from "react";
import styles from "./DiffView.module.css";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { KatBox } from "@amzn/katal-react";
import { stringifyHistoryTable } from "../../utils/JSONUtils";
import { SelectedDiff } from "../../common/Types";

type DiffViewProps = {
  selectedDiff: SelectedDiff;
};

export const DiffView: React.FC<DiffViewProps> = (props): ReactElement => {
  return (
    props.selectedDiff && (
      <KatBox className={`${styles.diffViewBox} kat-row kat-col-xs-12`}>
        <ReactDiffViewer
          oldValue={stringifyHistoryTable(JSON.parse(props.selectedDiff.left))}
          newValue={stringifyHistoryTable(JSON.parse(props.selectedDiff.right))}
          useDarkTheme={false}
          showDiffOnly={true}
          compareMethod={DiffMethod.WORDS}
        />
      </KatBox>
    )
  );
};
