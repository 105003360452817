import React, { ReactElement, useEffect } from "react";
import { KatBox, KatInput, KatDropdown, KatRadiobuttonGroup, KatButton, KatIcon } from "@amzn/katal-react";
import styles from "./SearchBar.module.css";
import { Stages } from "../../common/StageInfo";
import { marketplacesByRealm } from "../../common/MarketplaceInfo";
import { useTranslation } from "react-i18next";

type SearchBarProps = {
  customerID: string;
  customerIDInputConstraint: string;
  marketplace: string;
  stage: string;
  loading: boolean;
  searchDisabled: boolean;
  onCustomerIDChanged: (customerID: string) => void;
  onSearchButtonPressed: () => Promise<void>;
  onMarketplaceDropdownChanged: (marketplace: string) => void;
  onStageChanged: (stage: string) => void;
};

export const useEnterToSearch = (searchDisabled: boolean, loading: boolean, onSearchButtonPressed: () => Promise<void>): void => {
  useEffect(() => {
    function handleSubmit(e) {
      e.key === "Enter" && !searchDisabled && !loading && onSearchButtonPressed();
    }
    document.addEventListener("keydown", handleSubmit);
    return function cleanup() {
      document.removeEventListener("keydown", handleSubmit);
    };
  });
};

export const SearchBar: React.FC<SearchBarProps> = (props): ReactElement => {
  const { t } = useTranslation();

  useEnterToSearch(props.searchDisabled, props.loading, props.onSearchButtonPressed);

  const getOptionsForRealm = (realm) => {
    const items = marketplacesByRealm[realm];

    const itemList = [];
    items.forEach((item) => {
      itemList.push({
        name: `${props.stage === Stages.PROD.id ? item.marketplaceIdForProd : item.marketplaceIdForDevo} - ${item.id}`,
        value: item.id,
        icon: item.icon,
      });
    });

    return itemList;
  };

  return (
    <div className={styles.searchBar}>
      <KatBox variant="white">
        <h3>{t("search-bar-header")}</h3>
        <div className={`kat-row ${styles.searchRow}`}>
          <KatInput
            id={"consoleCustomerIDInput"}
            className={"kat-col-xs-3"}
            label={t("customerid-input-label")}
            type="search"
            placeholder="CustomerID"
            value={props.customerID}
            constraintEmphasis={props.customerIDInputConstraint ? t("customerid-input-constraint-emphasis") : ""}
            constraintLabel={props.customerIDInputConstraint}
            onInput={(event: any) => props.onCustomerIDChanged(event.target.value)}
          />
          <KatDropdown
            id={"consoleMarketplaceDropdown"}
            label={t("marketplace-dropdown-label")}
            className={"kat-col-xs-4"}
            options={[...getOptionsForRealm("NA"), ...getOptionsForRealm("EU"), ...getOptionsForRealm("FE")]}
            value={props.marketplace}
            searchable={true}
            placeholder="Marketplace"
            onChange={(event) => {
              props.onMarketplaceDropdownChanged(event.detail.value);
            }}
          />
          <KatRadiobuttonGroup
            id={"consoleStageInput"}
            name={t("stage-input-label")}
            label={t("stage-input-label")}
            className={`${styles.horizontalGroup} kat-col-xs-3`}
            options={[
              {
                label: Stages.DEVO.displayName,
                value: Stages.DEVO.id,
              },
              {
                label: Stages.PROD.displayName,
                value: Stages.PROD.id,
              },
            ]}
            value={props.stage !== Stages.PROD.id ? Stages.DEVO.id : Stages.PROD.id}
            onChange={(event) => props.onStageChanged(event.detail.value)}
          />
          <KatButton
            id="consoleSearchButton"
            onClick={props.onSearchButtonPressed}
            className={`${styles.searchButton} kat-col-xs-2`}
            variant="primary"
            loading={props.loading}
            disabled={props.searchDisabled}
          >
            <KatIcon name={props.searchDisabled ? "block" : "search"} size="small" slot="icon" /> <div>{t("search-button-label")}</div>
          </KatButton>
        </div>
      </KatBox>
    </div>
  );
};
