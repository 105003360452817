import React, { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { NavBar } from "../NavBar/NavBar";
import styles from "./KeyMasterHome.module.css";
import { TFunction, withTranslation } from "react-i18next";
import axios from "axios";
import { ApiGatewayAdapter } from "../../lib/ApiGatewayAdapter";
import { API_REGION_FOR_STAGE } from "../../common/AccountData";
import { KeyMasterBar } from "../KeyMasterBar/KeyMasterBar";
import { redirect } from "../../backend/dom";
import { KeyMasterAction, KeyMasterStringSide } from "../../common/Constants";
import { KeyMasterConverterResult } from "../../common/Types";
import { getErrorMessage } from "../../utils/JSONUtils";
import { KatAlert } from "@amzn/katal-react";
import { AuthConfig } from "../../lib/AuthConfig";
import { Stages } from "../../common/StageInfo";

type KeyMasterHomeState = {
  stage: string;
  realm: string;
  loading: boolean;
  decodedString: string;
  encodedString: string;
  keyMasterDataType: string;
  usePrefix: boolean;
  actionToPerform: KeyMasterAction;
  convertError: string;
};

type KeyMasterHomeProps = {
  t: TFunction;
};

class KeyMasterHome extends Component<KeyMasterHomeProps & RouteComponentProps, KeyMasterHomeState> {
  constructor(props: KeyMasterHomeProps & RouteComponentProps) {
    super(props);
    this.state = {
      stage: "",
      realm: "",
      loading: false,
      decodedString: "",
      encodedString: "",
      keyMasterDataType: "",
      usePrefix: false,
      actionToPerform: KeyMasterAction.ENCRYPT,
      convertError: null,
    };
  }

  async componentDidMount() {
    const { stage } = await axios.get("/settings.json").then((response) => response.data);
    const realm = stage == Stages.PROD.id ? "na" : "fe";
    this.setState(() => {
      return { stage: stage, realm: realm };
    });
    ApiGatewayAdapter.initializeApiClient(stage, API_REGION_FOR_STAGE[stage]);
  }

  onActionToPerformChanged = (previousAction: KeyMasterAction) => {
    const newAction = previousAction === KeyMasterAction.ENCRYPT ? KeyMasterAction.DECRYPT : KeyMasterAction.ENCRYPT;
    this.setState(() => {
      return {
        actionToPerform: newAction,
      };
    });
  };

  onStringChanged = (newString: string, stringSide: KeyMasterStringSide) => {
    if (stringSide === KeyMasterStringSide.DECODED_STRING) {
      this.setState(() => {
        return {
          decodedString: newString,
        };
      });
    } else {
      this.setState(() => {
        return {
          encodedString: newString,
        };
      });
    }
  };

  onKeyMasterDataTypeChanged = (newDataType: string) => {
    this.setState(() => {
      return {
        keyMasterDataType: newDataType,
      };
    });
  };

  onUsePrefixCheckboxChanged = (usePrefix: boolean) => {
    this.setState(() => {
      return {
        usePrefix: usePrefix,
      };
    });
  };

  onConvertButtonPressed = async () => {
    const actionToPerform = this.state.actionToPerform;
    const stringToConvert = actionToPerform === KeyMasterAction.ENCRYPT ? this.state.decodedString : this.state.encodedString;
    this.setState(() => {
      return {
        loading: true,
      };
    });
    const keyMasterConverterResult: KeyMasterConverterResult = await ApiGatewayAdapter.convertWithKeyMaster(
      stringToConvert,
      this.state.keyMasterDataType,
      this.state.usePrefix,
      actionToPerform,
      this.state.realm
    ).then(
      (result) => {
        return {
          data: result,
        };
      },
      (reason) => {
        return {
          error: this.props.t("api-error-format", {
            status: reason.response.status,
            error: getErrorMessage(reason.response.data),
          }),
        };
      }
    );
    this.setState(() => {
      return {
        loading: false,
      };
    });
    if (keyMasterConverterResult.error) {
      this.setState(() => {
        return {
          convertError: keyMasterConverterResult.error,
        };
      });
    } else {
      if (actionToPerform === KeyMasterAction.ENCRYPT) {
        this.setState(() => {
          return {
            encodedString: keyMasterConverterResult.data,
            convertError: null,
          };
        });
      } else {
        this.setState(() => {
          return {
            decodedString: keyMasterConverterResult.data,
            convertError: null,
          };
        });
      }
    }
  };

  onStageChanged = (stage: string) => {
    redirect(`https://${stage}.console.registration.b2b.amazon.dev/keymaster`);
  };

  onRealmChanged = (realm) => {
    this.setState(() => {
      return {
        realm: realm,
      };
    });
  };

  render(): ReactNode {
    return AuthConfig.isAdmin() ? (
      <div>
        <NavBar />
        <div className={styles.keyMasterMain}>
          {this.state.stage == Stages.PROD.id && (
            <KatAlert
              className={styles.errorAlert}
              id={"consoleKeyMasterProdAlert"}
              variant={"danger"}
              persistent={true}
              description={this.props.t("prod-alert-message")}
              header={this.props.t("prod-alert-header")}
            />
          )}
          <KeyMasterBar
            stage={this.state.stage}
            realm={this.state.realm}
            decodedString={this.state.decodedString}
            encodedString={this.state.encodedString}
            keyMasterDataType={this.state.keyMasterDataType}
            actionToPerform={this.state.actionToPerform}
            usePrefix={this.state.usePrefix}
            loading={this.state.loading}
            onStageChanged={this.onStageChanged}
            onRealmChanged={this.onRealmChanged}
            onStringChanged={this.onStringChanged}
            onActionToPerformChanged={this.onActionToPerformChanged}
            onKeyMasterDataTypeChanged={this.onKeyMasterDataTypeChanged}
            onUsePrefixCheckboxChanged={this.onUsePrefixCheckboxChanged}
            onConvertButtonPressed={this.onConvertButtonPressed}
          />
          {this.state.convertError && (
            <KatAlert description={this.state.convertError} header={this.props.t("keymaster-convert-api-error-header")} variant="danger" />
          )}
        </div>
      </div>
    ) : (
      <div>
        <NavBar />
        <div className={styles.keyMasterMain}>
          <KatAlert
            className={styles.errorAlert}
            id={"consoleKeyMasterNoAdminAlert"}
            variant={"danger"}
            persistent={true}
            description={this.props.t("not-authorized-alert-message")}
            header={this.props.t("not-authorized-alert-header")}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(KeyMasterHome));
