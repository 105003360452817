import React, { ReactElement } from "react";
import { ToolsButtonsBox } from "../ToolsButtonsBox/ToolsButtonsBox";
import styles from "./ResultAndToolsBox.module.css";
import { TableHistoryResult, TableResult } from "../../common/Types";
import { TablesBox } from "../TablesBox/TablesBox";
import { ToolsActionsBox } from "../ToolsActionsBox/ToolsActionsBox";
import { ToolsStatusBox } from "../ToolsStatusBox/ToolsStatusBox";
import { REGISTRATION_STATUS } from "../../common/Constants";

type ResultAndToolsBox = {
  isShowResults: boolean;
  isLoading: boolean;
  isLoadingDiff: boolean;
  isLoadingCancellation: boolean;
  isCancellationBeenExecuted: boolean;
  registrationFormDataResult: TableResult;
  registrationRequestResult: TableResult;
  abRegistrationResult: TableResult;
  abRegistrationHistoryResult: TableHistoryResult;
  bartonWebsiteHandler: () => void;
  bacHandler: () => void;
  accountExplainerHandler: () => void;
  corvegaHandler: () => void;
  onCancelBusinessRegistrationPressed: () => void;
};

export const ResultAndToolsBox: React.FC<ResultAndToolsBox> = (props): ReactElement => {
  const getRegistrationStatus = () => {
    if (!props.isLoading) {
      if (props.abRegistrationResult.data) {
        return REGISTRATION_STATUS[props.abRegistrationResult.data.status.toString()];
      } else if (props.abRegistrationHistoryResult.data) {
        return REGISTRATION_STATUS.CANCELLED;
      }
    }
    return REGISTRATION_STATUS.UNKNOWN;
  };

  const registrationStatus = getRegistrationStatus();

  const isCancellable =
    registrationStatus === REGISTRATION_STATUS.REGISTRATION_FAILED || registrationStatus === REGISTRATION_STATUS.REGISTRATION_PENDING;

  return (
    <div className={styles.resultAndToolsBox}>
      <div>
        <TablesBox
          isShowResults={props.isShowResults}
          isLoading={props.isLoading}
          registrationFormDataResult={props.registrationFormDataResult}
          registrationRequestResult={props.registrationRequestResult}
          abRegistrationResult={props.abRegistrationResult}
        />
      </div>
      <div className={styles.toolsColumn}>
        <ToolsStatusBox isShowResults={props.isShowResults} status={registrationStatus} />
        <ToolsActionsBox
          isShowResults={props.isShowResults}
          isLoading={props.isLoading}
          isCancellable={isCancellable && !props.isCancellationBeenExecuted}
          isLoadingCancellation={props.isLoadingCancellation}
          onCancelBusinessRegistrationPressed={props.onCancelBusinessRegistrationPressed}
        />
        <ToolsButtonsBox
          isShowResults={props.isShowResults}
          bartonWebsiteHandler={props.bartonWebsiteHandler}
          bacHandler={props.bacHandler}
          accountExplainerHandler={props.accountExplainerHandler}
          corvegaHandler={props.corvegaHandler}
        />
      </div>
    </div>
  );
};
